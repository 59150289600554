import React, { useEffect, useState } from 'react'
import { Option } from '../../models/Sektor'
import { getLokacijeTableDropdown } from '../../api/tableRS'
import SearchableSelect from '../SearchableSelect/SearchableSelect'

interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const LokacijeRsTraka: React.FC<DropdownProps> = ({
                                                      value,
                                                      initialValue,
                                                      onChange,
                                                      errors,
                                                      touched,
                                                      selectName,
                                                  }) => {
    const [lokacijeDropdown, setLokacijeDropdown] = useState<Array<Option>>([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getLokacijeTableDropdown()
                console.log('Response:', response)
                setLokacijeDropdown(mapData(response.data.data))
            } catch (error) {
                console.error('Error fetching dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data: Array<{ Naziv: string; Id: number }>) => {
        if (!Array.isArray(data)) {
            console.error('Expected an array but received:', data)
            return []
        }
        return data.map((item) => ({ label: item.Naziv, value: item.Id.toString() }))
    }

    return (
        <SearchableSelect
            options={lokacijeDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'lokacijeDropdown'}
            errors={errors}
            placeholder='Odaberi Lokaciju'
            touched={touched}
        />
    )
}

export default LokacijeRsTraka
