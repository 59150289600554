import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ArtikliOperacijeModel} from "../../models/ArtikliOperacije";
import {fetchArtikliOperacije, saveArtikliOperacije, updateArtikliOperacije} from "../../api/artikliOperacije";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import ArtikliGalanterija from "../../components/ArtikliGalanterija/ArtikliGalanterija";
import clsx from "clsx";
import SredstvaDropdown from "../../components/SredstvaDropdown/SredstvaDropdown";
import {ZapisiProizvodnjeGalanterija} from "../../models/ZapisiProizvodnjeGalanterija";
import {fetchRepromaterijaliUgovori} from "../../api/rolneUgovori";
import {
    fetchZapisiProizvodnjeGalanterija,
    updateZapisiProizvodnjeGalanterija
} from "../../api/zapisiProizvodnjeGalanterija";
import UposlenikDropdown from "../../components/UposlenikDropdown/UposlenikDropdown";

const ZapisiProizvodnjeDetails:React.FC = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)

    const initialValues = {
        idUposlenik: undefined,
        datum: undefined,
        startTime: undefined,
        endTime: undefined,
        kolicina: undefined,
    }
    const [initialData, setInitialData] = useState<Partial<ZapisiProizvodnjeGalanterija>>(initialValues)

    const validationSchema = Yup.object({
        endTime: Yup.string().required('Required'),
        kolicina: Yup.string().required('Required'),
        startTime: Yup.string().required('Required'),
    })

    useEffect(() => {
        if (IdFromURL) {
            setLoading(true)
            fetchZapisiProizvodnjeGalanterija(IdFromURL)
                .then((data) => {
                    const formattedDate = data.datum ? new Date(data.datum).toISOString().split('T')[0] : undefined;

                    const updatedData = {
                        ...data,
                        datum: formattedDate,
                    };
                    setInitialData(updatedData)
                    formik.resetForm({values: updatedData}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                let response;

                if (IdFromURL) {
                    response = await updateZapisiProizvodnjeGalanterija(values, IdFromURL);
                }

                console.log('API Response:', response);

                if (response?.error === 0) {
                    notifyToast(true, response.message);
                } else {
                    notifyToast(false, response?.message || 'An unexpected error occurred.');
                }
            } catch (error: any) {
                // Log the entire error object to see its structure
                console.error('Error fetching data: ', error);

                // Handle the error based on its structure
                const errorMessage = error?.response?.data?.message  // If using axios, error.response.data.message
                    || error?.message  // If it's a native Error object
                    || 'An unexpected error occurred.';

                // Display the error message using notifyToast
                notifyToast(false, errorMessage);
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <div className='row'>
            <ToastContainer />
            <div className='card mb-5 mb-xl-10 col-6'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <TitleComponent name='' />
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    {loading ? (
                        <LoadingComponent />
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className='card-body border-top p-9 row'>
                                {/* Artikal */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>Uposlenik</label>
                                        <div className='col-auto fv-row'>
                                            <UposlenikDropdown
                                                value={formik.values.idUposlenik}
                                                onChange={(name, value) => formik.setFieldValue('idUposlenik', value)}
                                                errors={formik.errors.idUposlenik}
                                                touched={formik.touched.idUposlenik}
                                                selectName='idUposlenik'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Naziv operacije */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto required col-form-label fw-bold fs-6'>Datum</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Datum'
                                            {...formik.getFieldProps('datum')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.datum &&
                                                        formik.errors.datum,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.datum &&
                                                        !formik.errors.datum,
                                                }
                                            )}
                                        />
                                        {formik.touched.datum && formik.errors.datum && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.datum}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Norma */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Početak
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Pocetak'
                                            {...formik.getFieldProps('startTime')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.startTime && formik.errors.startTime},
                                                {
                                                    'is-valid': formik.touched.startTime && !formik.errors.startTime,
                                                }
                                            )}
                                        />
                                        {formik.touched.startTime && formik.errors.startTime && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.startTime}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Norma min */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Kraj
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Kraj'
                                            {...formik.getFieldProps('endTime')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.endTime && formik.errors.endTime},
                                                {
                                                    'is-valid': formik.touched.endTime && !formik.errors.endTime,
                                                }
                                            )}
                                        />
                                        {formik.touched.endTime && formik.errors.endTime && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.endTime}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                       Količina
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='number'
                                            placeholder='Kolicina'
                                            {...formik.getFieldProps('kolicina')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.kolicina && formik.errors.kolicina},
                                                {
                                                    'is-valid': formik.touched.kolicina && !formik.errors.kolicina,
                                                }
                                            )}
                                        />
                                        {formik.touched.kolicina && formik.errors.kolicina && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.kolicina}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )
}
export default ZapisiProizvodnjeDetails;
