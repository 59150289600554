import React, {useState} from "react";
import {NalogIzvjestajModel} from "../../models/IzvjestajModel";
import * as Yup from "yup";
import {useFormik} from "formik";
import {periodicniIzvjestajKontrole} from "../../api/izvjestaj";
import notifyToast from "../../components/Toast/Toast";
import {ZapisiStavkeModel} from "../../models/ZapisiProizvodnjeModel";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import clsx from "clsx";
import moment from "moment/moment";
import {ucinakSredstva} from "../../api/sredstva";
import SredstvaDropdown from "../../components/SredstvaDropdown/SredstvaDropdown";
import {SredstvaIzvjestaj} from "../../models/Sredstva";

const UcinakSredstva:React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [nalogData, setNalog] = useState<SredstvaIzvjestaj[]>([]);

    // Initial values for the form
    const initialValues = {
        datumOd: "",
        datumDo: "",
        IdSredstva: "",
    };

    // Validation schema should match initialValues fields
    const validationSchema = Yup.object({
        datumOd: Yup.string().required("Datum Od is required"),
        datumDo: Yup.string().required("Datum Do is required"),
        IdSredstva: Yup.string().required("Datum Do is required"),
    });

    // Formik setup
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                const response = await ucinakSredstva(values);
                setNalog(response.data["data"]);
            } catch (error: any) {
                console.error("Error: ", error);
                notifyToast(false, error.response?.data?.message || "Error occurred while fetching data.");
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    const formatTime = (timeString) => {
        // Check if timeString exists and split it at '.'
        return timeString ? timeString.split('.')[0] : "----";
    };
    // Helper function to convert time string (hh:mm:ss) to total seconds
    const timeStringToSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        return (hours * 3600) + (minutes * 60) + seconds;
    };

    // Helper function to convert total seconds back to hh:mm:ss format
    const secondsToTimeString = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Sum the total time in seconds
    const totalTimeInSeconds = nalogData.reduce((acc, nalog) => {
        const timeString = formatTime(nalog.PotrebnoVrijeme);
        if (timeString !== "----") {
            acc += timeStringToSeconds(timeString);
        }
        return acc;
    }, 0);

    // Convert the total time in seconds to hh:mm:ss format
    const totalFormattedTime = secondsToTimeString(totalTimeInSeconds);

    return (
        <div className="card mb-5 mb-xl-10">
            <ToastContainer />
            <div className="card-header border-0 cursor-pointer" role="button">
                <div className="card-title m-0">
                    <TitleComponent name="Učinak sredstva" />
                </div>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
                <div className="card-body border-top p-9">
                    {nalogData.length === 0 ? (
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={formik.handleSubmit} noValidate className="form">
                                    <div className="card-body border-top p-9">
                                        <div className="row">
                                            {/* Datum Od Field */}
                                            <div className="col-4 mb-6">
                                                <label>Datum od</label>
                                                <input
                                                    type="date"
                                                    placeholder="Datum od"
                                                    {...formik.getFieldProps("datumOd")}
                                                    className={clsx("form-control form-control-lg form-control-solid", {
                                                        "is-invalid": formik.touched.datumOd && formik.errors.datumOd,
                                                        "is-valid": formik.touched.datumOd && !formik.errors.datumOd,
                                                    })}
                                                />
                                                {formik.touched.datumOd && formik.errors.datumOd ? (
                                                    <div className="invalid-feedback">{formik.errors.datumOd}</div>
                                                ) : null}
                                            </div>
                                            {/* Datum Do Field */}
                                            <div className="col-4 mb-6">
                                                <label>Datum do</label>
                                                <input
                                                    type="date"
                                                    placeholder="Datum do"
                                                    {...formik.getFieldProps("datumDo")}
                                                    className={clsx("form-control form-control-lg form-control-solid", {
                                                        "is-invalid": formik.touched.datumDo && formik.errors.datumDo,
                                                        "is-valid": formik.touched.datumDo && !formik.errors.datumDo,
                                                    })}
                                                />
                                                {formik.touched.datumDo && formik.errors.datumDo ? (
                                                    <div className="invalid-feedback">{formik.errors.datumDo}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-4 mb-6">
                                                <label>Sredstvo</label>
                                                <SredstvaDropdown
                                                    value={formik.values.IdSredstva}
                                                    onChange={(name, value) => formik.setFieldValue('IdSredstva', value)}
                                                    errors={formik.errors.IdSredstva}
                                                    touched={formik.touched.IdSredstva}
                                                    selectName='IdSredstva'
                                                />
                                                {formik.touched.IdSredstva && formik.errors.IdSredstva ? (
                                                    <div className="invalid-feedback">{formik.errors.IdSredstva}</div>
                                                ) : null}
                                            </div>
                                            {/* Submit Button */}
                                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                                >
                                                    {!loading && "Potvrdi"}
                                                    {loading && (
                                                        <span className="indicator-progress" style={{display: "block"}}>
                              Please wait...{" "}
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <h1 className="mb-6">Učinak sredstva za odabrani period: {totalFormattedTime}</h1>
                                <table className="table align-middle table-bordered gy-5 no-footer">
                                    <thead>
                                    <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                        <th className="text-white" rowSpan={2}>#</th>
                                        <th className="text-white">Šifra</th>
                                        <th className="text-white">Artikal</th>
                                        <th className="text-white">Norma | M2/ MIN</th>
                                        <th className="text-white">Količina (m2)</th>
                                        <th className="text-white">Vrijeme</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {nalogData.map((nalog, index) => (
                                        <tr key={nalog.SifraArtikla}>
                                            <td>{index + 1}</td>
                                            <td>{nalog.SifraArtikla || "----"}</td>
                                            <td>{nalog.NazivArtikla || "----"}</td>
                                            <td>{nalog.Norma || "----"}</td>
                                            <td>{nalog.Kolicina || "----"}</td>
                                            <td>{formatTime(nalog.PotrebnoVrijeme)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default UcinakSredstva;
