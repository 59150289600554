import axios from 'axios'
import {GlavniIzvjestajModel, NalogIzvjestajModel} from '../models/IzvjestajModel'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/glavniIzvjestaj`
const API2 = `${API_URL}/paneli/glavniIzvjestajV2`
const API_NALOG = `${API_URL}/paneli/izvjestajNaloga`
const API_NALOG_2 = `${API_URL}/paneli/kontrola-kvaliteta/dnevniIzvjestaj`
const API_NALOG_3 = `${API_URL}/paneli/kontrola-kvaliteta/periodicniIzvjestaj`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getGlavniIzvjestaj(params: any) {
  return await axios.get<Array<GlavniIzvjestajModel>>(API, {headers: headers, params: params})
}
export async function getGlavniIzvjestajV2(params: any) {
  return await axios.get<Array<GlavniIzvjestajModel>>(API2, {headers: headers, params: params})
}
export async function nalogIzvjestaj(params: any) {
  return await axios.get<Array<NalogIzvjestajModel>>(API_NALOG, {headers: headers, params: params})
}

export async function dnevniIzvjestajKontrole(params: any) {
  return await axios.get<Array<NalogIzvjestajModel>>(API_NALOG_2, {headers: headers, params: params})
}

export async function periodicniIzvjestajKontrole(params: any) {
  return await axios.get<Array<NalogIzvjestajModel>>(API_NALOG_3, {headers: headers, params: params})
}