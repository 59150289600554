import {useFormik} from 'formik';
import {ToastContainer} from 'react-toastify';
import TitleComponent from '../../../../components/TitleComponent/TitleComponent';
import React, {useState} from 'react';
import clsx from 'clsx';
import {nalogIzvjestaj} from '../../../../api/izvjestaj';
import notifyToast from '../../../../components/Toast/Toast';
import * as Yup from 'yup';
import {NalogIzvjestajModel} from '../../../../models/IzvjestajModel';
import moment from 'moment';

// Define the structure of the expected response from the API
interface ApiResponse<T> {
    error: number; // Example: 0 for success, 1 for error
    message: string;
    data: T; // The data is the main NalogIzvjestajModel object
}

const DetaljanIzvjestajNalogaPanela: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [nalog, setNalog] = useState<NalogIzvjestajModel | null>(null); // Single NalogIzvjestajModel or null

    // Initial values for the form
    const initialValues = {
        brojNaloga: '',
        stavka: '',
    };

    // Validation schema should match initialValues fields
    const validationSchema = Yup.object({
        brojNaloga: Yup.string().required('Broj Naloga is required'),
        stavka: Yup.string(),
    });

    // Formik setup
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            try {
                // Make the API call with the form values
                const response = await nalogIzvjestaj(values);
                setNalog(response.data['data']);

            } catch (error: any) {
                console.error('Error: ', error);
                notifyToast(false, error.response?.data?.message || 'Error occurred while fetching data.');
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div className='card-header border-0 cursor-pointer' role='button'>
                <div className='card-title m-0'>
                    <TitleComponent name='Detaljan Izvještaj Naloga'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {/* Show form if no data, otherwise show table */}
                    {!nalog ? (
                        // Render the form
                        <div className='row'>
                            <div className='col-12'>
                                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                    <div className='card-body border-top p-9'>
                                        <div className='row'>
                                            {/* Broj Naloga Field */}
                                            <div className='col-4 mb-6'>
                                                <label>Broj Naloga</label>
                                                <input
                                                    type='text'
                                                    placeholder='Broj Naloga'
                                                    {...formik.getFieldProps('brojNaloga')}
                                                    className={clsx('form-control form-control-lg form-control-solid', {
                                                        'is-invalid': formik.touched.brojNaloga && formik.errors.brojNaloga,
                                                        'is-valid': formik.touched.brojNaloga && !formik.errors.brojNaloga,
                                                    })}
                                                />
                                                {formik.touched.brojNaloga && formik.errors.brojNaloga ? (
                                                    <div className='invalid-feedback'>{formik.errors.brojNaloga}</div>
                                                ) : null}
                                            </div>

                                            {/* Stavka Field */}
                                            <div className='col-4 mb-6'>
                                                <label>Stavka</label>
                                                <input
                                                    type='text'
                                                    placeholder='Stavka'
                                                    {...formik.getFieldProps('stavka')}
                                                    className='form-control form-control-lg form-control-solid'
                                                />
                                            </div>

                                            {/* Submit Button */}
                                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary'
                                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                                >
                                                    {!loading && 'Potvrdi'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...{' '}
                                                            <span
                                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        // Render the table with nalog data if available
                        <div className='row'>
                            <div className='col-12'>
                                {/*<h3 className='mt-4'>Nalog: {nalog.proizvodnja.brojNaloga} / {nalog.proizvodnja.stavka}</h3>*/}
                                <table className="table align-middle table-bordered gy-5 no-footer">
                                    <thead>
                                    <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                        <th className="text-white fs-3">Broj Naloga</th>
                                        <th className="text-white fs-3">Ponuda</th>
                                        <th className="text-white fs-3">Artikal</th>
                                        <th className="text-white fs-3">Komercijalista</th>
                                        <th className="text-white fs-3">Kupac</th>
                                        <th className="text-white fs-3">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{nalog.proizvodnja.brojNaloga} / {nalog.proizvodnja.stavka}</td>
                                        <td>{nalog.proizvodnja.brojPonude}</td>
                                        <td>{nalog.proizvodnja.artikal}</td>
                                        <td>{nalog.proizvodnja.kreiraoKorisnik}</td>
                                        <td>{nalog.proizvodnja.kupac}</td>
                                        <td>{nalog.proizvodnja.statusPro} / {moment(nalog.proizvodnja.statusProDatum).format('DD-MM-YYYY HH:mm')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* Paketi Section */}
                            <div className='col-12'>
                                <h2 className='mt-4 mb-4'>Paketi</h2>
                                <table className="table align-middle table-bordered gy-5 no-footer">
                                    <thead>
                                    <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                        <th className="text-white fs-3">Paket #</th>
                                        <th className="text-white fs-3">Dimenzije</th>
                                        <th className="text-white fs-3">Komada</th>
                                        <th className="text-white fs-3">Isporučio</th>
                                        <th className="text-white fs-3">Kontrolisao</th>
                                        <th className="text-white fs-3">Isporučen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {nalog.paketi?.map((paket, index) => (
                                        <tr key={index}>
                                            <td>{paket.nazivPanela}</td>
                                            <td>{paket.duzina}/{paket.sirinaPaketa}/{paket.visinaPaketa}</td>
                                            <td>{paket.brPanela}</td>
                                            <td>{paket.uposlenik ? paket.uposlenik.UposlenikImePrezime : 'N/A'}</td>
                                            <td>{paket.kontrolisao_uposlenik ? paket.kontrolisao_uposlenik.UposlenikImePrezime : 'N/A'}</td>
                                            <td>{moment(paket.isporucenDatum).format('DD-MM-YYYY HH:mm')}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>
                                <h2 className="mt-4 mb-4">Zapisi proizvodnje</h2>
                                <table className="table align-middle table-bordered gy-5 no-footer">
                                    <thead>
                                    <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                        <th className="text-white fs-3">Smjena</th>
                                        <th className="text-white fs-3">Linija</th>
                                        <th className="text-white fs-3">Nalog Završen</th>
                                        <th className="text-white fs-3">Nužni Škart</th>
                                        <th className="text-white fs-3">Škart Upisan</th>
                                        <th className="text-white fs-3">Razlog Škarta</th>
                                        <th className="text-white fs-3">Upisao Škart</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {nalog.zapisi?.map((zapis, index) => (
                                        <tr key={index}>
                                            <td>{zapis.Smjena}</td>
                                            <td>{zapis.Masina}</td>
                                            <td>{zapis.NalogZavrsen}</td>
                                            <td>{zapis.NuzniSkart}</td>
                                            <td>{moment(zapis.SkartUpisan).format('DD-MM-YYYY HH:mm')}</td>
                                            <td>{zapis.RazlogSkarta}</td>
                                            <td>{zapis.UpisaoSkart}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>
                                {/* Zapisi Stavke Section */}
                                <h2 className="mt-4 mb-4">Zapisi stavke</h2>
                                <table className="table align-middle table-bordered gy-5 no-footer">
                                    <thead>
                                    <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                        <th className="text-white fs-3">Parametar</th>
                                        <th className="text-white fs-3">Vrijednost</th>
                                        <th className="text-white fs-3">Uposlenik</th>
                                        <th className="text-white fs-3">Datum</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {nalog.zapisiStavke &&
                                        Object.keys(nalog.zapisiStavke).map((key) =>
                                            nalog.zapisiStavke[key].map((zapisSt, index) => (
                                                <tr key={index}>
                                                    <td>{zapisSt.MetaKey}</td>
                                                    <td>{zapisSt.MetaValue}</td>
                                                    <td>{zapisSt.uposlenik ? zapisSt.uposlenik.UposlenikImePrezime : 'N/A'}</td>
                                                    <td>{moment(zapisSt.Kreirano).format('DD-MM-YYYY HH:mm')}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>
                                <h2 className="mt-4 mb-4">Kontrola kvaliteta zapisi</h2>
                                {nalog.kontrolaKvaliteta?.map((item, index) => (
                                    <table key={index} className="table align-middle table-bordered gy-5 no-footer">
                                        <thead>
                                        <tr  className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                            <th colSpan={2} className="text-white text-center fs-2">Kontrola Kvaliteta {index + 1}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Uzorak na dužnom m:</td>
                                            <td>{item.UzorakNaDuznomM ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Gustoca Uzorka 1:</td>
                                            <td>{item.GustocaUzorka1 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Gustoca Uzorka 2:</td>
                                            <td>{item.GustocaUzorka2 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Gustoca Uzorka 3:</td>
                                            <td>{item.GustocaUzorka3 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A1 Zatezna Cvrstoca U1:</td>
                                            <td>{item.A1ZateznaCvrstocaU1 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A1 Zatezna Cvrstoca U2:</td>
                                            <td>{item.A1ZateznaCvrstocaU2 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A1 Zatezna Cvrstoca U3:</td>
                                            <td>{item.A1ZateznaCvrstocaU3 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A1 Zatezni Modul Ecc:</td>
                                            <td>{item.A1ZatezniModulEcc ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A1 Uzorak Zadovoljava:</td>
                                            <td>{item.TestA1Zadovoljava ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A2 Pritisna Čvrstoca Uzorak 1</td>
                                            <td>{item.A2PritisnaCvrstocaUzorak1 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A2 Pritisna Čvrstoca Uzorak 2</td>
                                            <td>{item.A2PritisnaCvrstocaUzorak2 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A2 Pritisna Čvrstoca Uzorak 3</td>
                                            <td>{item.A2PritisnaCvrstocaUzorak1 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A2 Modul Smicanja</td>
                                            <td>{item.A3ModulSmicanja ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A2 Uzorak Zadovoljava:</td>
                                            <td>{item.TestA2Zadovoljava ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A3 Čvrstoca Smicanja 1</td>
                                            <td>{item.A3CvrstocaSmicanja1 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A3 Čvrstoca Smicanja 2</td>
                                            <td>{item.A3CvrstocaSmicanja2 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A3 Čvrstoca Smicanja 3</td>
                                            <td>{item.A3CvrstocaSmicanja3 ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A3 Modul Smicanja</td>
                                            <td>{item.A3ModulSmicanja ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A3 Uzorak Zadovoljava:</td>
                                            <td>{item.TestA3Zadovoljava ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A4 Čvrstoca Smicanja</td>
                                            <td>{item.A4CvrstocaNaSmicanje ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A4 Modul Smicanja</td>
                                            <td>{item.A4ModulSmicanja ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A4 Uzorak Zadovoljava:</td>
                                            <td>{item.TestA4Zadovoljava ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A5 Napon Gužvanja</td>
                                            <td>{item.A5NaponGuzvanja ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A5 Uzorak Zadovoljava:</td>
                                            <td>{item.TestA5Zadovoljava ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A10 Gustoća Uzorka</td>
                                            <td>{item.A10GustocaUzorka ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A10 Lamda</td>
                                            <td>{item.A10Lamda ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>A10 Uzorak Zadovoljava:</td>
                                            <td>{item.TestA10Zadovoljava ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Komentar:</td>
                                            <td>{item.Komentar ?? 'N/A'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetaljanIzvjestajNalogaPanela;
