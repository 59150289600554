/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../../../_websoft/helpers'
import {uposleniciIzvjestajRadUNormi} from '../../../../api/uposleniciIzvjestaj'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import notifyToast from '../../../../components/Toast/Toast'

const RadUProizvodnji = () => {
    const location = useLocation()
    const [data, setData] = useState<any>()
    const [galanterijaArray, setGalanterijaArray] = useState<any>([])
    const [nalogArray, setnalogArray] = useState<any>([])

    //  Get data from api
    const updatePage = () => {
        const params = {
            uposlenikId: location.state.uposlenikId,
            datumOd: location.state.datumOd,
            datumDo: location.state.datumDo,
        }
        uposleniciIzvjestajRadUNormi(params)
            .then((response) => {
                console.log({response})
                const {galanterija, nalog} = response.data.data
                setData(response.data.data)
                // Convert the object to an array
                const galanterijaList = Object.values(galanterija[0]).filter(
                    (item) => typeof item === 'object'
                )
                console.log({galanterijaList})
                setGalanterijaArray(galanterijaList)
                const nalogList = Object.values(nalog[0]).filter((item) => typeof item === 'object')
                console.log({nalogList})
                setnalogArray(nalogList)
            })
            .catch((error) => notifyToast(false, error.response.data.message))
    }

    const extractDateAndTime = (data) => {
        const date = moment(data).format('DD-MM-YYYY')
        const time = moment(data).format('h:mm')

        return {date, time}
    }

    useEffect(() => {
        updatePage()
    }, [])

    return (
        <KTCard>
            <KTCardBody>
                {data && (
                    <div>
                        <div className='row'>
                            <div className='alert alert-primary'>
                                <div className='row p-2'>
                                    <h1 className='mt-3 mb-3'>Radno vrijeme
                                        uposlenika: {data.uposlenikData.UposlenikImePrezime}</h1>
                                    <div className='separator mt-3 mb-3'></div>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                            <h5>ERV Prijavljen sati: {data.ukupnoPrijavljenSati} h</h5>
                                        </span>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                            <h5>Ukupno radio: {data.ukupnoRadioSatiBezHonorarniIVikend} h</h5>
                                        </span>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                            <h5>Honorarni sati: {data.honorarniSati} h</h5>
                                        </span>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                            <h5>Rad subotom: {data.totalSatiSubota} h</h5>
                                        </span>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                            <h5>Rad nedjeljom: {data.totalSatiNedjelja} h</h5>
                                        </span>
                                </div>
                                <div className='row p-6'>
                                    <div className='col-lg-4 col-sm-12 mt-6'>
                                        <h2>Galanterija</h2>
                                        <span
                                            className={`badge w-auto p-5 m-1 ${Math.round((data.galanterija[0].satiUNormi / data.ukupnoRadioSatiBezHonorarniIVikend) * 100) >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                            <h5> Vrijeme provedeno: {data.galanterija[0].satiUNormi} h |
                                                 ( {Math.round((data.galanterija[0].satiUNormi / data.ukupnoPrijavljenSati) * 100)} % )
                                            </h5>
                                        </span>
                                        <span
                                            className={`badge w-auto p-5 m-1 ${data.projekNormeGalanterija >= 100 ? 'badge-success' : 'badge-danger'}`}>
                                            <h5> Prosjek norme: {data.projekNormeGalanterija}</h5>
                                        </span>
                                    </div>
                                    <div className='col-lg-4 col-sm-12 mt-6'>
                                        <h2>Radni nalozi</h2>
                                        <span
                                            className={`badge w-auto p-5 m-1 ${Math.round((data.nalog[0].radioSati / data.ukupnoRadioSatiBezHonorarniIVikend) * 100) >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                            <h3> Vrijeme provedeno: {data.nalog[0].radioSati} h  |
                                                 ( {Math.round((data.nalog[0].radioSati / data.ukupnoPrijavljenSati) * 100)} % )
                                            </h3>
                                        </span>
                                        <span
                                            className={`badge w-auto p-5 m-1 ${data.projekNormeRadniNalozi >= 100 ? 'badge-success' : 'badge-danger'}`}>
                                            <h5> Prosjek norme: {data.projekNormeRadniNalozi}</h5>
                                        </span>
                                    </div>
                                    <div className='col-lg-4 col-sm-12 mt-6'>
                                        <h2>Prosjek norme</h2>
                                        <span
                                            className={`badge w-auto p-5 m-1 ${data.projekNormeRadniNalozi >= 1 ? 'badge-success' : 'badge-danger'}`}>
                                            <h1 style={{fontSize: '60px'}}>{data.projekNormi} %</h1>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data.galanterija[0].satiUNormi > 0 && (
                            <>
                                <h2 className='my-10'>Galanterija</h2>
                                <table className='table align-middle table-bordered gy-5 no-footer'>
                                    <thead>
                                    <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'>
                                        <th className='text-white'>ID</th>
                                        <th className='text-white'>Datum</th>
                                        <th className='text-white'>Artikal/Operacija</th>
                                        <th className='text-white'>Početak</th>
                                        <th className='text-white'>Kraj</th>
                                        <th className='text-white'>Količina</th>
                                        <th className='text-white'>Norma</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {galanterijaArray &&
                                        galanterijaArray.map((item, index) => {
                                            const {
                                                start,
                                                dan,
                                                artikal,
                                                operacije,
                                                kraj,
                                                uradjenoKomada,
                                                ispunjenostNorme
                                            } =
                                                item
                                            const {date, time: startTime} = extractDateAndTime(start)
                                            const {time: endTime} = extractDateAndTime(kraj)
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {date}
                                                        <br/>
                                                        {dan}
                                                    </td>
                                                    <td>
                                                        {artikal} / {operacije}
                                                    </td>
                                                    <td>{startTime}</td>
                                                    <td>{endTime}</td>
                                                    <td>{uradjenoKomada}</td>
                                                    <td>{ispunjenostNorme}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </>
                        )}
                        <hr/>
                        {data.nalog[0].radioSati > 0 && (
                            <>
                                <h1 className='my-10'>Radni nalozi</h1>
                                {nalogArray && nalogArray.map((item, index) =>
                                        item instanceof Object && (
                                            <>
                                                <table className='table align-middle table-bordered gy-5 no-footer'>
                                                    <thead>
                                                    <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'>
                                                        <th className='text-white'>BrojNaloga</th>
                                                        <th className='text-white'>Početak</th>
                                                        <th className='text-white'>Kraj</th>
                                                        <th className='text-white'>Radio Minuta</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>{item.BrojNaloga ?? ''}</td>
                                                        <td>{item.Pocetak ? moment(item.Pocetak).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                        <td>{item.Kraj ? moment(item.Kraj).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                        <td>{item.VrijemeNalogaMinuta ?? ''}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                {item.Stavke && item.Stavke.length > 0 && (
                                                    <table className='table align-middle table-bordered gy-5 no-footer'>
                                                        <thead>
                                                        <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-secondary p-2'>
                                                            <th>Šifra</th>
                                                            <th>Artikal</th>
                                                            <th>Količina</th>
                                                            <th>Norma/Min</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {item.Stavke.map((stavka, sIndex) => (
                                                            <tr key={sIndex}>
                                                                <td>{stavka.Sifra}</td>
                                                                <td>{stavka.Naziv}</td>
                                                                <td>{stavka.Kolicina}</td>
                                                                <td>{stavka.PotrebnoVrijemeMin}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </>
                                        )
                                )}
                            </>
                        )}
                    </div>
                )}
            </KTCardBody>
        </KTCard>
    )
}

export default RadUProizvodnji
