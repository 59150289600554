import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IntervencijeOdrzavanja} from "../../../models/IntervencijeOdrzavanja";
import {fetchIntervencija, saveIntervencija, updateIntervencija} from "../../../api/intervencijeOdrzavanja";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import PrijavaKvaraDropdown from "../../../components/PrijavaKvaraDropdown/PrijavaKvaraDropdown";
import clsx from "clsx";
import UposlenikDropdown from "../../../components/UposlenikDropdown/UposlenikDropdown";
import {KTIcon} from "../../../../_websoft/helpers";
import StatusIntervencijeDropdown from "../../../components/StatusDropdown/StatusIntervencijeDropdown";
import {useAuth} from "../../../modules/auth";
import SmjenePanelaDropdown from "../../paneli/components/SmjenePanelaDropdown/SmjenePanelaDropdown";

const DodajIntervenciju: React.FC = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(false)

    const {currentUser} = useAuth()
    const initialValues = {
        Id: undefined,
        BrojIntervencije: IdFromURL.toString(),
        Datum: undefined,
        DatumKraj: undefined,
        OpisRadova: undefined,
        Status: undefined,
        Izvrsilac: [],
        Voditelj: currentUser?.UposlenikId.toString(),
        UtroseniMaterijal: undefined,
        UtrosenoVrijemeSati: undefined,
        UtrosenoVrijemeMinute: undefined,
        KoristenAlat: undefined,
        IzmjenaUlja: undefined,
        TipUlja: undefined,
        KolicinaUlja: undefined,
        RadniSati: undefined,
        SljedecaIzmjena: undefined,
        Napomena: undefined,
        RazlogKvara: undefined,
        RazlogKvaraDodatno: undefined,
        KvarNapravilaSmjena: undefined,
        KvarNapravioUposlenik: undefined,
    }
    const [initialData, setInitialData] = useState<Partial<IntervencijeOdrzavanja>>(initialValues);

    const [IzvrsilacCount, setIzvrsilacCount] = useState(1)

    const validationSchema = Yup.object({
        //BrojIntervencije: Yup.string().required('Required'),
        OpisRadova: Yup.string().required('Required'),
        UtrosenoVrijemeMinute: Yup.string().required('Required'),
        Status: Yup.string().required('Required'),
        Voditelj: Yup.string().required('Required')
    })
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            try {
                let response
                response = await saveIntervencija(values)
                if (response.error === 0) {
                    notifyToast(true, response.message)
                    setTimeout(() => {
                        navigate(-1)
                    }, 3000)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Intervenciju'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className='card-body border-top p-9'>
                            <div className='row '>
                                {/* Prijava Id */}
                                <div className='col-6 mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Prijava Id
                                    </label>
                                    <PrijavaKvaraDropdown
                                        value={formik.values.BrojIntervencije}
                                        onChange={(name, value) => formik.setFieldValue('BrojIntervencije ', value)}
                                        errors={formik.errors.BrojIntervencije}
                                        touched={formik.touched.BrojIntervencije}
                                        selectName='BrojIntervencije '
                                    />
                                </div>
                                {formik.touched.BrojIntervencije && formik.errors.BrojIntervencije && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.BrojIntervencije}</div>
                                    </div>
                                )}
                            </div>
                            <div className='row mb-6'>
                                {/* Datum */}
                                <div className='col mb-6'>
                                    <label className='col-form-label required fw-bold fs-6'>Datum (početak)</label>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Datum'
                                            {...formik.getFieldProps('Datum')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.Datum && formik.errors.Datum},
                                                {
                                                    'is-valid': formik.touched.Datum && !formik.errors.Datum,
                                                }
                                            )}
                                        />
                                        {formik.touched.Datum && formik.errors.Datum && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Datum}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Datum */}
                                <div className='col mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Datum (kraj)
                                    </label>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Datum'
                                            {...formik.getFieldProps('DatumKraj')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.DatumKraj && formik.errors.DatumKraj},
                                                {
                                                    'is-valid': formik.touched.DatumKraj && !formik.errors.DatumKraj,
                                                }
                                            )}
                                        />
                                        {formik.touched.DatumKraj && formik.errors.DatumKraj && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.DatumKraj}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Pocetak */}
                                <div className='col mb-6'>
                                    <label className='col-form-label required fw-bold fs-6'>Utrošeno vrijeme</label>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <input
                                                type='datetime'
                                                placeholder='Sati'
                                                {...formik.getFieldProps('UtrosenoVrijemeSati')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid':
                                                            formik.touched.UtrosenoVrijemeSati &&
                                                            formik.errors.UtrosenoVrijemeSati,
                                                    },
                                                    {
                                                        'is-valid':
                                                            formik.touched.UtrosenoVrijemeSati &&
                                                            !formik.errors.UtrosenoVrijemeSati,
                                                    }
                                                )}
                                            />
                                            {formik.touched.UtrosenoVrijemeSati && formik.errors.UtrosenoVrijemeSati && (
                                                <div className='fv-plugins-message-container'>
                                                    <div
                                                        className='fv-help-block'>{formik.errors.UtrosenoVrijemeSati}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-4'>
                                            <input
                                                type='datetime'
                                                placeholder='Minute'
                                                {...formik.getFieldProps('UtrosenoVrijemeMinute')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid':
                                                            formik.touched.UtrosenoVrijemeMinute &&
                                                            formik.errors.UtrosenoVrijemeMinute,
                                                    },
                                                    {
                                                        'is-valid':
                                                            formik.touched.UtrosenoVrijemeMinute &&
                                                            !formik.errors.UtrosenoVrijemeMinute,
                                                    }
                                                )}
                                            />
                                            {formik.touched.UtrosenoVrijemeMinute && formik.errors.UtrosenoVrijemeMinute && (
                                                <div className='fv-plugins-message-container'>
                                                    <div
                                                        className='fv-help-block'>{formik.errors.UtrosenoVrijemeMinute}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-12'>
                                    {/* Opis radova */}
                                    <div className='col mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                            Opis Radova
                                        </label>

                                        <div className='col-lg-8 fv-row'>
                      <textarea
                          placeholder='OpisRadova'
                          {...formik.getFieldProps('OpisRadova')}
                          className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {'is-invalid': formik.touched.OpisRadova && formik.errors.OpisRadova},
                              {
                                  'is-valid': formik.touched.OpisRadova && !formik.errors.OpisRadova,
                              }
                          )}
                      />
                                        </div>
                                        {formik.touched.OpisRadova && formik.errors.OpisRadova && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.OpisRadova}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col mb-6'>
                                        <div className='col'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                Voditelj
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <UposlenikDropdown
                                                    value={formik.values.Voditelj}
                                                    onChange={(name, value) => formik.setFieldValue('Voditelj', value)}
                                                    errors={formik.errors.Voditelj}
                                                    touched={formik.touched.Voditelj}
                                                    selectName='Voditelj'
                                                />
                                            </div>
                                        </div>
                                        {formik.touched.Voditelj && formik.errors.Voditelj && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Voditelj}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Korišten Alat</label>

                                        <div className='col-lg-8 fv-row'>
                      <textarea
                          placeholder='Koristen Alat'
                          {...formik.getFieldProps('KoristenAlat')}
                          className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                  'is-invalid': formik.touched.KoristenAlat && formik.errors.KoristenAlat,
                              },
                              {
                                  'is-valid': formik.touched.KoristenAlat && !formik.errors.KoristenAlat,
                              }
                          )}
                      />
                                            {formik.touched.KoristenAlat && formik.errors.KoristenAlat && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.KoristenAlat}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-lg-4 mb-6'>
                                        <label className='col-form-label fw-bold fs-6'>
                                            <span className=''>Zamjena</span>
                                        </label>
                                        <select className='form-control' {...formik.getFieldProps('IzmjenaUlja')}>
                                            <option value=''>Odaberi...</option>
                                            <option value='1'>Da</option>
                                            <option value='0'>Ne</option>
                                        </select>
                                        {formik.touched.IzmjenaUlja && formik.errors.IzmjenaUlja && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.IzmjenaUlja}</div>
                                            </div>
                                        )}
                                    </div>

                                    {formik.values.IzmjenaUlja === '1' && (
                                        <div className='row'>
                                            {/* TipUlja */}
                                            <div className='col-6 mb-6'>
                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                    Tip Ulja
                                                </label>

                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='text'
                                                        placeholder='Tip Ulja'
                                                        {...formik.getFieldProps('TipUlja')}
                                                        className={clsx(
                                                            'form-control form-control-lg form-control-solid',
                                                            {'is-invalid': formik.touched.TipUlja && formik.errors.TipUlja},
                                                            {
                                                                'is-valid': formik.touched.TipUlja && !formik.errors.TipUlja,
                                                            }
                                                        )}
                                                    />
                                                    {formik.touched.TipUlja && formik.errors.TipUlja && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.TipUlja}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* KolicinaUlja */}
                                            <div className='col-6 mb-6'>
                                                <label className='col-form-label required fw-bold fs-6'>Kolicina</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='text'
                                                        placeholder='Kolicina Ulja'
                                                        {...formik.getFieldProps('KolicinaUlja')}
                                                        className={clsx(
                                                            'form-control form-control-lg form-control-solid',
                                                            {
                                                                'is-invalid':
                                                                    formik.touched.KolicinaUlja && formik.errors.KolicinaUlja,
                                                            },
                                                            {
                                                                'is-valid':
                                                                    formik.touched.KolicinaUlja && !formik.errors.KolicinaUlja,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {/* RadniSati */}
                                            <div className='col-6 mb-6'>
                                                <label className='col-form-label required fw-bold fs-6'>Radni
                                                    Sati</label>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='text'
                                                        placeholder='Radni sati'
                                                        {...formik.getFieldProps('RadniSati')}
                                                        className={clsx(
                                                            'form-control form-control-lg form-control-solid',
                                                            {'is-invalid': formik.touched.RadniSati && formik.errors.RadniSati},
                                                            {
                                                                'is-valid': formik.touched.RadniSati && !formik.errors.RadniSati,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {/* SljedecaIzmjena */}
                                            <div className='col-6 mb-6'>
                                                <label className='col-form-label required fw-bold fs-6'>
                                                    Sljedeca Izmjena
                                                </label>

                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='text'
                                                        placeholder='Sljedeca izmjena'
                                                        {...formik.getFieldProps('SljedecaIzmjena')}
                                                        className={clsx(
                                                            'form-control form-control-lg form-control-solid',
                                                            {
                                                                'is-invalid':
                                                                    formik.touched.SljedecaIzmjena && formik.errors.SljedecaIzmjena,
                                                            },
                                                            {
                                                                'is-valid':
                                                                    formik.touched.SljedecaIzmjena && !formik.errors.SljedecaIzmjena,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {' '}
                                {/* end col-6 */}
                                <div className='col-lg-6 col-sm-12'>
                                    {/* Izvrsilac */}
                                    <div className='col mb-6'>
                                        <div className='col'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                Izvrsioci
                                            </label>
                                            <div className='row mb-6'>
                                                {Array.from({length: formik.values.Izvrsilac!.length}, (_, i) => (
                                                    <div className='row'>
                                                        <div className='col-lg-6 fv-row mb-6'>
                                                            <UposlenikDropdown
                                                                value={formik.values.Izvrsilac![i]}
                                                                onChange={(name, value) => {
                                                                    var al = formik.values.Izvrsilac ?? []
                                                                    al.splice(i, 1, value)
                                                                    formik.setFieldValue('Izvrsilac', al)
                                                                }}
                                                                errors={formik.errors.Izvrsilac}
                                                                touched={formik.touched.Izvrsilac}
                                                                selectName='Izvrsilac'
                                                            />
                                                        </div>

                                                        <div className='col-lg-2'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-danger'
                                                                onClick={() => {
                                                                    var al = formik.values.Izvrsilac ?? []
                                                                    al.splice(i, 1)
                                                                    formik.setFieldValue('Izvrsilac', al)
                                                                }}
                                                            >
                                                                <KTIcon iconName='minus' className='fs-2'/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='col-lg-2'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-primary'
                                                        onClick={() => {
                                                            // setIzvrsilacCount(IzvrsilacCount + 1)
                                                            var al = formik.values.Izvrsilac ?? []
                                                            console.log('val ', formik.values.Izvrsilac)
                                                            al.push(undefined)
                                                            formik.setFieldValue('Izvrsilac', al)
                                                        }}
                                                    >
                                                        <KTIcon iconName='plus' className='fs-2'/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='col mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Komentar</label>

                                                <div className='col-lg-8 fv-row'>
                                                      <textarea
                                                          placeholder='Komentar'
                                                          {...formik.getFieldProps('Napomena')}
                                                          className={clsx(
                                                              'form-control form-control-lg form-control-solid',
                                                              {
                                                                  'is-invalid': formik.touched.Napomena && formik.errors.Napomena,
                                                              },
                                                              {
                                                                  'is-valid': formik.touched.Napomena && !formik.errors.Napomena,
                                                              }
                                                          )}
                                                      />
                                                    {formik.touched.Napomena && formik.errors.Napomena && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div
                                                                className='fv-help-block'>{formik.errors.Napomena}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col mb-6'>
                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                    Status
                                                </label>

                                                <StatusIntervencijeDropdown
                                                    value={formik.values.Status}
                                                    onChange={(name, value) => formik.setFieldValue('Status', value)}
                                                    errors={formik.errors.Status}
                                                    touched={formik.touched.Status}
                                                    selectName='Status'
                                                />
                                                {formik.touched.Status && formik.errors.Status && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.Status}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-4 mb-6'>
                                    <label className='col-form-label fw-bold fs-6'>
                                        <span className=''>Razlog kvara</span>
                                    </label>
                                    <select className='form-control' {...formik.getFieldProps('RazlogKvara')}>
                                        <option value=''>Odaberi...</option>
                                        <option value='Ljudski faktor'>Ljudski faktor</option>
                                        <option value='Dotrajalost'>Dotrajalost</option>
                                        <option value='Loše održavanje'>Loše održavanje</option>
                                        <option value='Ostalo'>Ostalo</option>
                                    </select>
                                    {formik.touched.IzmjenaUlja && formik.errors.IzmjenaUlja && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.IzmjenaUlja}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-6 mb-6'>
                                    <label className='col-form-label fw-bold fs-6'>
                                        <span className=''>Razlog kvara dodatno. Ukoliko je razlog kvara ostalo, u polje ispod upisati
                                        razlog kvara!</span>
                                    </label>
                                    <textarea
                                        placeholder='Razlog kvara'
                                        {...formik.getFieldProps('RazlogKvaraDodatno')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.Napomena && formik.errors.RazlogKvaraDodatno,
                                            },
                                            {
                                                'is-valid': formik.touched.Napomena && !formik.errors.RazlogKvaraDodatno,
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-4 mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className=''>Smjena (paneli)</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <SmjenePanelaDropdown
                                            value={formik.values.KvarNapravilaSmjena}
                                            onChange={(name, value) => formik.setFieldValue('KvarNapravilaSmjena', value)}
                                            errors={formik.errors.KvarNapravilaSmjena}
                                            touched={formik.touched.KvarNapravilaSmjena}
                                            selectName='KvarNapravilaSmjena'
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className=''>Uzrokovao Uposlenik</span>
                                    </label>
                                    <UposlenikDropdown
                                        value={formik.values.KvarNapravioUposlenik}
                                        onChange={(name, value) => formik.setFieldValue('KvarNapravioUposlenik', value)}
                                        errors={formik.errors.KvarNapravioUposlenik}
                                        touched={formik.touched.KvarNapravioUposlenik}
                                        selectName='KvarNapravioUposlenik'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}

            </div>
        </div>
    )
}
export default DodajIntervenciju;
