import React, {useState} from "react";
import {NalogIzvjestajModel} from "../../../../models/IzvjestajModel";
import * as Yup from "yup";
import {useFormik} from "formik";
import {dnevniIzvjestajKontrole, nalogIzvjestaj} from "../../../../api/izvjestaj";
import notifyToast from "../../../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../../components/TitleComponent/TitleComponent";
import clsx from "clsx";
import moment from "moment/moment";

const DnevniIzvjestajPanela:React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [nalog, setNalog] = useState<NalogIzvjestajModel | null>(null); // Single NalogIzvjestajModel or null

    // Initial values for the form
    const initialValues = {
        datum: '',
    };

    // Validation schema should match initialValues fields
    const validationSchema = Yup.object({
        datum: Yup.string(),
    });

    // Formik setup
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                // Make the API call with the form values
                const response = await dnevniIzvjestajKontrole(values);

                setNalog(response.data['data']);
            } catch (error: any) {
                console.error('Error: ', error);
                notifyToast(false, error.response?.data?.message || 'Error occurred while fetching data.');
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer />
            <div className='card-header border-0 cursor-pointer' role='button'>
                <div className='card-title m-0'>
                    <TitleComponent name='Dnevni Izvještaj Proizvodnje i Kontrole Kvaliteta' />
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {/* Show form if no data, otherwise show table */}
                    {!nalog ? (
                        // Render the form
                        <div className='row'>
                            <div className='col-12'>
                                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                    <div className='card-body border-top p-9'>
                                        <div className='row'>
                                            {/* Broj Naloga Field */}
                                            <div className='col-4 mb-6'>
                                                <label>Datum</label>
                                                <input
                                                    type='date'
                                                    placeholder='Datum'
                                                    {...formik.getFieldProps('datum')}
                                                    className={clsx('form-control form-control-lg form-control-solid', {
                                                        'is-invalid': formik.touched.datum && formik.errors.datum,
                                                        'is-valid': formik.touched.datum && !formik.errors.datum,
                                                    })}
                                                />
                                                {formik.touched.datum && formik.errors.datum ? (
                                                    <div className='invalid-feedback'>{formik.errors.datum}</div>
                                                ) : null}
                                            </div>
                                            {/* Submit Button */}
                                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary'
                                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                                >
                                                    {!loading && 'Potvrdi'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        // Render the table with nalog data if available
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='mb-6'>Rađeni nalozi</h2>
                                <table className="table align-middle table-bordered gy-5 no-footer">
                                    <thead>
                                    <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                        <th className="text-white">Nalog</th>
                                        <th className="text-white">Artikal</th>
                                        <th className="text-white">Kupac</th>
                                        <th className="text-white">Linija</th>
                                        <th className="text-white">Završen</th>
                                        <th className="text-white">Rolne G</th>
                                        <th className="text-white">Rolne D</th>
                                        <th className="text-white">Kreirao</th>
                                        <th className="text-white">Datum</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {nalog?.radjeniNalozi?.map((radjeniNalog, index) => (
                                        <tr key={index}>
                                            <td>{radjeniNalog.BrojNaloga} ({radjeniNalog?.paneli_proizvodnja.stavka ?? ' '})</td>
                                            <td>{radjeniNalog.paneli_proizvodnja.artikal}</td>
                                            <td>{radjeniNalog.paneli_proizvodnja.kupac}</td>
                                            <td>{radjeniNalog.Masina}</td>
                                            <td>{radjeniNalog.NalogZavrsen}</td>
                                            <td>{radjeniNalog.paneli_proizvodnja.rolneG}</td>
                                            <td>{radjeniNalog.paneli_proizvodnja.rolneD}</td>
                                            <td>{radjeniNalog.uposlenik ? radjeniNalog.uposlenik.UposlenikImePrezime : 'N/A'}</td>
                                            <td>{moment(radjeniNalog.Kreirano).format('DD-MM-YYYY HH:mm')}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            {nalog?.kontrolaKvaliteta && (
                                <div className='col-12'>
                                    <h2 className='mt-4'>Kontrola Kvaliteta</h2>
                                    {nalog.kontrolaKvaliteta.map((item, index) => (
                                        <table key={index} className="table align-middle table-bordered gy-5 no-footer">
                                            <thead>
                                            <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-4">
                                                <th colSpan={2} className="text-center">
                                                    <h2 className='text-white'>Kontrola Kvaliteta # {index + 1}</h2>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Id</td>
                                                <td>{item.Id}</td>
                                            </tr>
                                            <tr>
                                                <td>Broj Naloga</td>
                                                <td>{item.paneli_proizvodnja?.brojNaloga ?? 'N/A'} ({item.paneli_proizvodnja?.stavka ?? ' '})</td>
                                                {/* Safely access the property */}
                                            </tr>
                                            <tr>
                                                <td>Kreirao Uposlenik</td>
                                                <td>{item.uposlenik?.UposlenikImePrezime ?? 'N/A'}</td>
                                                {/* Safely access the property */}
                                            </tr>
                                            {/* Dynamically render non-null values */}
                                            {Object.keys(item).map((key) => {
                                                if (
                                                    item[key] !== null &&
                                                    ![
                                                        'IdProizvodnje',
                                                        'Id',
                                                        'UzorakNaredio',
                                                        'UzorakZadovoljava',
                                                        'Izmjenjeno',
                                                        'Komentar',
                                                        'UposlenikId',
                                                        'uposlenik',
                                                        'naredio_uzorak',
                                                        'paneli_proizvodnja'
                                                    ].includes(key)
                                                ) {
                                                    // Check if the value is an object
                                                    const value = typeof item[key] === 'object'
                                                        ? JSON.stringify(item[key]) // Convert object to string to display
                                                        : ['Izmjenjeno', 'Kreirano'].includes(key)
                                                            ? moment(item[key]).format('DD-MM-YYYY HH:mm')
                                                            : item[key];

                                                    return (
                                                        <tr key={key}>
                                                            <td>{key.replace(/([A-Z])/g, ' $1')}</td>
                                                            {/* Add space before uppercase letters */}
                                                            <td>{value}</td>
                                                            {/* Render primitive or stringified object */}
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                            </tbody>
                                        </table>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default DnevniIzvjestajPanela;
