/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react'
import {VisibilityState} from '@tanstack/react-table'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {ParamsModelReprmat, RepromaterijaliModel} from '../../models/RepromaterijaliModel'
import {deleteRepromaterijali, getRepromaterijali} from '../../api'
import notifyToast from '../../components/Toast/Toast'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import TablePagination from '../../components/TablePagination/TablePagination'
import {Button, Modal, ModalHeader} from 'react-bootstrap'
import ReproMaterijaliModal from '../../components/ReproMaterijaliModal/ReproMaterijaliModal'
import ColumnSearch from '../../components/ColumnSearch/ColumnSearch'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import GetFilterRepromaterijala from '../../components/GetFilterRepromaterijala/GetFilterRepromaterijala'
import ReprmatHiddenColumns from '../../components/ReprmatHiddenColumns/ReprmatHiddenColumns'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import MinMaxFilter from '../../components/MinMaxFilter/MinMaxFilter'
import {PaginationModel} from '../../models/PaginationModel'

import {AgGridReact, CustomCellRendererProps} from 'ag-grid-react'
import {ColDef, GridApi, ValueFormatterParams} from 'ag-grid-community'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {LicenseManager} from 'ag-grid-enterprise';
import { setCookie, getCookie } from '../../service/cookie'
import {bosnianLocaleText} from '../../service/ag-grid-localization'
const agGridLicenseKey = process.env.REACT_APP_AG_GRID_LICENSE_KEY;

if (agGridLicenseKey) {
    LicenseManager.setLicenseKey(agGridLicenseKey);
} else {
    console.error("AG Grid license key is not defined. Please set REACT_APP_AG_GRID_LICENSE_KEY in your environment.");
}

const RepromaterijaliLista: React.FC = () => {
    const [repromaterijali, setRepromaterijali] = useState<RepromaterijaliModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [columnApi, setColumnApi] = useState(null);

    const [params, setparams] = useState<ParamsModelReprmat>({
        per_page: 10,
        page: 1,
        brojRolne: '',
        kategorija: '',
        boja: '',
        debljina: '',
        sirina: '',
        kodBoje: '',
        materijal: '',
        kvalitet: '',
        proizvodjac: '',
        dobavljac: '',
        status: '',
        skladiste: '',
        lokacija: '',
    })
    const gridRef = useRef<AgGridReact>(null)

    const [filter, setfilter] = useState('')
    const [isModalOpen, setisModalOpen] = useState(false)
    const [isToggleOpen, setisToggleOpen] = useState(false)
    const [isSumaOpen, setisSumaOpen] = useState(false)
    const [suma, setSuma] = useState(0)

    const [rowSelection, setrowSelection] = useState<RepromaterijaliModel[]>([])
    const [rezervacijeData, setRezervacijeData] = useState('')

    const onGridReady = (params) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };
    // Suma.
    const handleSuma = () => {
        if (gridApi) {
            let totalSum = 0;
            gridApi.forEachNodeAfterFilter((rowNode) => {
                // Make sure you convert to Number as your data might be string
                // Check if the value is not undefined or null before adding to totalSum
                if (rowNode.data.ReprMatTrenutnaKolicina) {
                    totalSum += Number(rowNode.data.ReprMatTrenutnaKolicina);
                }
            });
            setSuma(totalSum); // Assuming setSuma is the method you use to update the state for displaying sum
        }
    };

    const updatePage = () => {
        setLoading(true)
        getRepromaterijali(params)
            .then((response) => {

                setRepromaterijali(response.data['data'])
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
                // setRowSelection({})
            })
    }

    useEffect(() => {
        // @ts-ignore
        setparams({...params, per_page: pagination.perPage, page: pagination.curPage})
        getRepromaterijali(params).then((response) => {
            setRepromaterijali(response.data['data'])
            const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
            setpagination({
                curPage: response.data['current_page'],
                totalPage: totalPage,
                perPage: pagination.perPage,
            })
            setLoading(false)
        })
    }, [])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            minWidth: 100,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        }
    }, [])


    const columns = [
        {
            headerName: 'Broj rolne',
            field: 'ReprMatId',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            filter: 'agNumberColumnFilter',
            valueGetter : params => {
                return parseFloat(params.data.ReprMatId)
            },
        },
        {
            headerName: 'Proizvođač',
            field: 'proizvodjac.PartnerNaziv',
            filter: 'agSetColumnFilter',
            hide: false,
        },
        {
            headerName: 'S/N',
            field: 'ReprMatSeriski',
            hide: false,
        },
        {
            headerName: 'Garancija',
            field: 'garancija.MetaValue',
            hide: false,
        },
        {
            headerName: 'Debljina',
            field: 'ReprMatDebljina',
            filter: 'agNumberColumnFilter',
            valueGetter : params => {
                return parseFloat(params.data.ReprMatDebljina)
            },
            valueFormatter : params => {
                if(params.value != null && params.value != undefined){
                    return params.value.toFixed(2)
                }else{
                    return ''
                }
            },
        },
        {
            headerName: 'Širina',
            field: 'ReprMatSirina',
            filter: 'agNumberColumnFilter',
            valueGetter : params => {
                return parseFloat(params.data.ReprMatSirina)
            },
        },
        {
            headerName: 'Kvalitet',
            field: 'kvalitet.MatKvalitetNaziv',
            hide: false,
        },
        {
            headerName: 'Zaštita',
            field: 'zastita.MetaValue',
            hide: false,
        },
        {
            headerName: 'Dužina',
            field: 'ReprMatTrenutnaDuzina',
            filter: 'agNumberColumnFilter',
            valueGetter : params => {
                return parseFloat(params.data.ReprMatTrenutnaDuzina)
            },
        },
        {
            headerName: 'Količina',
            field: 'ReprMatTrenutnaKolicina',
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            valueGetter : params => {
                return parseFloat(params.data.ReprMatTrenutnaKolicina)
            },
            valueFormatter : params => {
                if(params.value != null && params.value != undefined){
                    return params.value.toFixed(2)
                }else{
                    return ''
                }
            },
        },
        {
            id: 'kodBoje',
            headerName: 'Kod Boje',
            field: 'kod_boje.KodBojeNaziv',
            hide: false,
        },
        {
            headerName: 'Šarža',
            hide: false,
            field: 'stavka.ugovor.BrojUgovora',
            valueGetter: (params) => {
                const brojUgovora = params.data.stavka?.ugovor?.BrojUgovora ?? '';
                const id = params.data.stavka?.Id ?? '';

                return [brojUgovora, id].filter(Boolean).join(' / ');
            },
        },
        {
            headerName: 'Strana A',
            hide: false,
            children: [
                {
                    field: 'struktura_a.MetaValue',

                    headerName: 'Struktura',
                    hide: false,
                },
                {
                    field: 'boja_lica.BojaNaziv',

                    headerName: 'Boja Lica',
                    hide: false,
                },
                {
                    field: 'boja_a.MetaValue',
                    filter: 'agNumberColumnFilter',
                    headerName: 'Debljina',
                    valueGetter : params => {
                        return parseFloat(params.data.boja_a.MetaValue)
                    },
                },
            ],
        },
        {
            headerName: 'Strana B',
            children: [
                {
                    field: 'struktura_b.MetaValue',

                    headerName: 'Struktura',
                    hide: false,
                },
                {
                    field: 'boja_nalicja.BojaNaziv',

                    headerName: 'Boja Lica',
                    hide: false,
                },
                {
                    field: 'boja_b.MetaValue',
                    filter: 'agNumberColumnFilter',
                    headerName: 'Debljina',
                    valueGetter : params => {
                        return parseFloat(params.data.boja_b.MetaValue)
                    },
                },
            ],
            hide: false,
        },
        {
            field: 'kategorija.KatNaziv',
            headerName: 'Kategorija',
            hide: false,
        },
        {
            headerName: 'Lokacija',
            field: 'lokacija',
            hide: false,
            valueGetter: (params) => {
                return `${params.data.ReprMatLokacija ?? ''} | ${params.data.ReprMatLokacija2 ?? '-'}`;
            },
            cellRendererFramework: (params) => {
                return (
                    <div>
                        {params.value}
                    </div>
                );
            }
        },
        {
            headerName: 'Ulaz',
            field: 'ReprMatUlazBroj',
            hide: false,
        },
        {
            headerName: 'Dobavljač',
            field: 'dobavljac.PartnerNaziv',
            hide: false,
        },
        {
            headerName: 'Skladište',
            field: 'skladiste.SkladisteNaziv',
            cellRenderer: (props) => {
                const skladiste = props.data.skladiste || {};
                const cellStyle = {
                    backgroundColor: skladiste.SkladisteBoja || 'transparent',
                    width: '100%',
                    height: '100%',
                    padding: '5px',
                };
                return <div style={cellStyle}>{skladiste.SkladisteNaziv}</div>;
            },
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                textFormatter: function(input) {
                    if (!input) return null;
                    return input.toString().toLowerCase();
                },
                valueGetter: function(params) {
                    return params.data.skladiste ? params.data.skladiste.SkladisteNaziv.toLowerCase() : null;
                }
            }
        },
     /*   {
            headerName: 'Status',
            field: 'status',
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
                // Default styles for the cell
                const cellStyle = {
                    backgroundColor: params.value ? params.value.Boja : 'transparent',
                    width: '100%',
                    height: '100%',
                    padding: '5px',
                };

                // Conditional content based on 'rezervacije'
                const rezervacijeContent = params.data.rezervacije ? (
                    <>
                        * {params.data.rezervacije.RezKupac} <br />
                        P: {params.data.rezervacije.RezPonuda} <br />
                    </>
                ) : (
                    <><br /></> // Add space if no 'rezervacije'
                );

                // Return the formatted cell content
                return (
                    <div style={cellStyle}>
                        {params.value ? params.value.Naziv : ''} {rezervacijeContent}
                    </div>
                );
            },
            hide: false,
            filterParams: {
                filterOptions: ['contains'],
                textFormatter: function (input) {
                    return input ? input.toString().toLowerCase() : '';
                },
                valueGetter: function (params) {
                    console.log('Current row data:', params.data); // Debugging: log out data to see what's being processed

                    let statusName = params.data.status ? params.data.status.Naziv : '';
                    let rezKupac = params.data.rezervacije ? params.data.rezervacije.RezKupac : '';
                    let rezPonuda = params.data.rezervacije ? params.data.rezervacije.RezPonuda : '';

                    let filterValue = [statusName, rezKupac, rezPonuda].join(' ').toLowerCase();
                    console.log('Filter value:', filterValue); // Debugging: log out what string is used for filtering

                    return filterValue;
                }
            }

        },*/
        {
            headerName: 'Status',
            field: 'status.Naziv',
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
                // Apply background color from the status object, if available
                const backgroundColor = params.data.status && params.data.status.Boja ? params.data.status.Boja : 'transparent';
                const cellStyle = {
                    backgroundColor: backgroundColor,
                    width: '100%',
                    height: '100%',
                    padding: '5px'
                };

                // Prepare content from rezervacije if available
                const rezervacijeContent = params.data.rezervacije ? (
                    <>
                        * ZA: {params.data.rezervacije.RezKupac} P: {params.data.rezervacije.RezPonuda}
                    </>
                ) : null;
                const rezervacijeContentSeriski = params.data.rezervacije_seriski ? (
                    <>
                        * ZA: {params.data.rezervacije_seriski.RezKupac}  P: {params.data.rezervacije_seriski.RezPonuda}
                    </>
                ) : null;
                // Combine the status name and the rezervacije content for display
                return (
                    <div style={cellStyle}>
                        {params.value} {rezervacijeContent} {rezervacijeContentSeriski}
                    </div>
                );
            },
            hide: false,
            filterParams: {
                filterOptions: ['contains'],
                valueGetter: function (params) {
                    console.log('Filtering for:', params.data);
                    // Include both the main field and the rezervacije details for filtering
                    const statusName = params.data.status ? params.data.status.Naziv.toLowerCase() : '';
                    const rezKupac = params.data.rezervacije ? params.data.rezervacije.RezKupac.toLowerCase() : '';
                    const rezPonuda = params.data.rezervacije ? params.data.rezervacije.RezPonuda.toLowerCase() : '';
                    return [statusName, rezKupac, rezPonuda].join(' ');
                }
            }
        },
        {
            headerName: 'Akcija',
            field: 'ReprMatId',
            hide: false,
            cellRenderer: (props: CustomCellRendererProps) => (
                <TableActionButton
                    onDelete={() => onDelete(props.value)}
                    onEdit={() => {
                        onEdit(props.value)
                    }}
                />
            ),
        },
    ]

    const onSelectionChanged = (event) => {
        const rows = event.api.getSelectedRows()
        console.log(rows)
        setrowSelection(rows)
    }

    useEffect(() => {
        updatePage()
    }, [params])

    const onDelete = (id) => {
        deleteRepromaterijali(id)
            .then((response) => {
                setparams({ ...params, per_page: pagination.perPage, page: pagination.curPage });
                notifyToast(true, response.data.message);
            })
            .catch((response) => {
                notifyToast(false, response.data.message);
            });
    };


    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/repromaterijal/izmjena/${rowData}`)
    }

    const gridOptions = {
        // Other grid options...
        floatingFilter: true,
    };

    const filterData = (values) => {
        const params = {
            per_page: 10,
            page: 1,
            brojRolne: values.ReprMatId,
            kategorija: values.ReprMatKategorija,
            boja: values.ReprMatBojaLica,
            debljina: values.ReprMatDebljina,
            sirina: values.ReprMatSirina,
            kodBoje: values.ReprMatKodBoje,
            materijal: values.ReprMatMaterijal,
            kvalitet: values.ReprMatKvalitet,
            proizvodjac: values.ReprMatProizvodjac,
            dobavljac: values.ReprMatDobavljac,
            status: values.ReprMatStatus,
            skladiste: values.ReprMatSkladiste,
            lokacija: values.ReprMatLokacija,
        }
        setparams(params)
    }
    const [colDefs, setColDefs] = useState(columns)
    const [currentColumns, setCurrentColumns] = useState(columns);
    useEffect(() => {
        const savedColumnStr = getCookie('repromaterijal_lista_columns');
        if (savedColumnStr) {
            const savedColumn = JSON.parse(savedColumnStr);
            let _columns = [...columns]; // Assuming 'columns' is the initial state or props of your column definitions
            _columns.forEach((item, index) => {
                // Check if corresponding saved column settings exist and apply 'hide' settings
                if (savedColumn[index]) {
                    item.hide = !!savedColumn[index].hide;

                    // Check if both item and savedColumn at this index are supposed to have children
                    if (item.children && savedColumn[index].children && Array.isArray(item.children) && Array.isArray(savedColumn[index].children)) {
                        item.children.forEach((childItem, childIndex) => {
                            // Ensure saved settings for this child exist before accessing
                            if (savedColumn[index].children[childIndex]) {
                                childItem.hide = !!savedColumn[index].children[childIndex].hide;
                            }
                        });
                    }
                }
            });
            setColDefs(_columns);
            setCurrentColumns(_columns);
        }
    }, []);
    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            'quickFilterText',
            (document.getElementById('filter-text-box') as HTMLInputElement).value
        )
    }, [])
    const onColumnVisible = (e) => {
        console.log(e)
        if (e.column) {
            const columnDef = e.column.getColDef();
            changeColumnDef(columnDef)
        }
        else if (e.columns) {
            e.columns.forEach((column) => {
                const columnDef = column.getColDef();
                changeColumnDef(columnDef)
            })
        }
    };
    console.log(repromaterijali,'repromaterijali')
    const changeColumnDef = (columnDef) => {
        let _currentColumns = [...currentColumns]
        _currentColumns.forEach((item) => {
          if (item.field == columnDef.field || (columnDef.field == 'ReprMatId' && item.headerName == columnDef.headerName)) {
            item.hide = !item.hide
          }
          if (item?.children && item.children.length) {
            item.children.forEach((childItem) => {
              if (childItem.field == columnDef.field || (columnDef.field == 'ReprMatId' && item.headerName == columnDef.headerName)) {
                childItem.hide = !childItem.hide
              }
            })
          }
        })
        setCurrentColumns(_currentColumns)
        setCookie('repromaterijal_lista_columns', JSON.stringify(_currentColumns), 100);
    }

    const formatNumber = (number, locales = 'bs-BA', maximumFractionDigits = 2) => {
        return new Intl.NumberFormat(locales, {
            maximumFractionDigits: maximumFractionDigits,
            minimumFractionDigits: maximumFractionDigits // This ensures that we always have two decimal places
        }).format(number);
    };

    return (
        <KTCard>
            <ToastContainer/>
            {/* Modal to edit reservations. */}
            <ReproMaterijaliModal
                isModalOpen={isModalOpen}
                setisModalOpen={(value) => setisModalOpen(value)}
                rolna={rezervacijeData}
                updateData={updatePage}
            />
            {/* Modal to hide/show columns */}
            <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
                <ModalHeader closeButton={true} className='h3'>
                    Suma: {formatNumber(suma)} kg {'   '} {formatNumber(suma / 1000)} t.
                </ModalHeader>
            </Modal>
            <KTCardBody className='py-4'>
                <div className='row mb-10 mt-3'>
                    {/* TODO: remaing implementation */}
                    <GetFilterRepromaterijala
                        getValues={(values) => {
                            filterData(values)
                        }}
                        rowSelection={rowSelection}
                        updatePage={() => updatePage()}
                        filterLoading={loading}
                    />
                </div>

                <div className='d-flex justify-content-between mb-6'>
                    <div className='col-3'>
                        <input
                            className='form-control form-control-lg form-control-solid'
                            type='text'
                            id='filter-text-box'
                            placeholder='Filter...'
                            onInput={onFilterTextBoxChanged}
                        />
                    </div>
                    <div data-kt-user-table-toolbar='row'>
                        <button
                            className='btn btn-light-primary me-3'
                            onClick={() => {
                                handleSuma()
                                setisSumaOpen(true)
                            }}
                        >
                            <KTIcon iconName='calculator' className='fs-2'/>
                            Suma
                        </button>
                     {/*   <button className='btn btn-light-primary me-3' onClick={() => setisToggleOpen(true)}>
                            <KTIcon iconName='plus' className='fs-2'/>
                            Dodaj Kolone
                        </button>*/}
                        {/* TODO: table export remaining */}
                        {/* <ExportButton table={table} filename={'RepromaterijaliLista'} /> */}
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    // <div className='table-responsive mt-3 '>
                    <div className='ag-theme-balham' style={{height: 500}}>
                        {/* @ts-ignore */}
                        <AgGridReact
                            onGridReady={onGridReady}
                            ref={gridRef}
                            rowData={repromaterijali} // @ts-ignore
                            columnDefs={colDefs}
                            rowSelection='multiple'
                            reactiveCustomComponents
                            suppressRowTransform={true}
                            defaultColDef={defaultColDef}
                            onSelectionChanged={onSelectionChanged}
                            onColumnVisible={onColumnVisible}
                            enableCharts={true}
                            localeText={bosnianLocaleText}
                        />
                    </div>
                )}
                <TablePagination
                    updatePage={(curPage, perPage) =>
                        setparams({...params, per_page: perPage, page: curPage})
                    }
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default RepromaterijaliLista
