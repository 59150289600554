/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'

const MAX_FILE_SIZE = 1450000  //

const validFileExtensions = {image: ['jpg', 'png']}
function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1
}

interface Props {
  setFile: Function
  label: string
  slikaUrl: string | undefined
  isFileValid: Function
}
const ImageComponent: FC<Props> = ({setFile, label = 'Slika', slikaUrl, isFileValid}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const initialValues = {Slika: undefined}
  const validationSchema = Yup.object({
    Slika: Yup.mixed()
      .notRequired()
      .nullable()
      .test(
        'FILE_SIZE',
        'Slika treba biti manja od 1.4 Mb', // @ts-ignoree
        (value) => !value || value.size <= MAX_FILE_SIZE
      )
      .test(
        'is-valid-type',
        'Slika treba biti sljedeceg formata: jpeg, png.', // @ts-ignoree
        (value) => !value || isValidFileType(value && value.name.toLowerCase(), 'image')
      ),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })
  // Function to set image file.
  function handleImageChange(e) {
    setFile(e.target.files[0])
    formik.setFieldValue('Slika', e.target.files[0])
  }

  // Function for handling click event on image input.
  const handleImageBtnClick = (event) => {
    if (hiddenFileInput.current) hiddenFileInput.current.click()
  }
  useEffect(() => {
    isFileValid(formik.isValid)
  }, [formik.isValid])
  return (
    <div className='col mb-6'>
      <label className='col-lg-12 col-form-label fw-bold fs-6'>{label}</label>
      <div className='col-lg-4'>
        <div
          className='image-input image-input-outline'
          data-kt-image-input='true'
          onClick={handleImageBtnClick}
        >
          {formik.values.Slika ? (
            <>
              <img
                {...formik.getFieldProps('Slika')}
                className={clsx(
                  'image-input-wrapper w-250px h-250px',
                  {
                    'is-invalid': formik.touched.Slika && formik.errors.Slika,
                  },
                  {
                    'is-valid': formik.touched.Slika && !formik.errors.Slika,
                  }
                )}
                src={URL.createObjectURL(formik.values.Slika)}
                alt=''
              />

              {formik.errors.Slika && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.Slika}</div>
                </div>
              )}
            </>
          ) : (
            <>
              <img
                className='image-input-wrapper w-250px h-250px'
                // @ts-ignore
                src={slikaUrl!}
                alt=''
              />

              <div className='fv-help-block'>{formik.errors.Slika}</div>
            </>
          )}
        </div>

        <input
          type='file'
          onChange={handleImageChange}
          ref={hiddenFileInput}
          style={{display: 'none'}}
        />
      </div>
    </div>
  )
}

export default ImageComponent
