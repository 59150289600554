import React, {useEffect, useState} from "react";
import {ArtikliOperacijeModel, ParamsModelArtikliOperacije} from "../../models/ArtikliOperacije";
import {PaginationModel} from "../../models/PaginationModel";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {deleteArtikliOperacije, getArtikliOperacije} from "../../api/artikliOperacije";
import notifyToast from "../../components/Toast/Toast";
import {TableActionButton} from "../../components/TableActionBtn/TableActionButton";
import {Link, useNavigate} from "react-router-dom";
import {KTCard, KTCardBody, KTIcon} from "../../../_websoft/helpers";
import {ToastContainer} from "react-toastify";
import ArtikliGalanterija from "../../components/ArtikliGalanterija/ArtikliGalanterija";
import TableSearch from "../../components/TableSearch/TableSearch";
import ExportButton from "../../components/TableExportToExcel/ExportButton";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import TablePagination from "../../components/TablePagination/TablePagination";
import {getInventureRolni} from "../../api/inventure";
import {ParamsModelInventure} from "../../models/inventure";
import moment from 'moment';
import moments from "moment/moment";

const RepromaterijaliInvLista:React.FC = () => {
    const [data, setData] = useState<ArtikliOperacijeModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setparams] = useState<ParamsModelInventure>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const updatePage = () => {
        setLoading(true)
        getInventureRolni(params)
            .then((response) => {
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'Kategorija',
            header: 'Kategorija',
            accessorKey: 'Vrsta',
        },
        {
            id: 'Pocetak',
            header: 'Pocetak',
            accessorFn: (row) => `${moments(row.Pocetak).format('DD-MM-YYYY')}`,
        },
        {
            id: 'Kraj',
            header: 'Kraj',
            accessorKey: 'Kraj',
            accessorFn: (row) => {
                // Check if the date is valid before formatting
                return row.Kraj && moment(row.Kraj).isValid() ? moment(row.Kraj).format('DD-MM-YYYY') : '';
            },
        },
        {
            id: 'Uposlenik',
            header: 'Uposlenik',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
        {
            id: 'Skenirano rolni',
            header: 'Skenirano rolni',
            accessorKey: 'SkeniranoRolni',
        },
        {
            id: 'Status',
            header: 'Status',
            accessorKey: 'status.Naziv',
        },
        {
            id: 'Izvještaj',
            accessorKey: 'Izvještaj',
            cell: ({ row }) => (
                <button
                    className="btn btn-link p-0"
                    onClick={() =>
                        row.original.Vrsta === 'Rolne'
                            ? navigate(`izvjestaj/${row.original.Id}`)
                            : navigate(`../trake/izvjestaj/${row.original.Id}`)
                    }
                >
                    <i className="bi bi-file-earmark-pdf-fill" style={{ color: 'red', fontSize: '2.5rem' }}></i>
                </button>
            ),
        },
        {
            header: 'Akcija',
            accessorKey: 'Akcija',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.Id)}
                    onEdit={() => {
                        onEdit(row.original.Id)
                    }}
                />
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const onDelete = (id) => {
        deleteArtikliOperacije(id)
            .then((response) => {
                notifyToast(true, response)
                setparams({per_page: pagination.perPage, page: pagination.curPage})
            })
            .catch((error) => {
                notifyToast(false, error.data['message'])
            })
    }
    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/inventure/rolne-trake/izmjena/${rowData}`)
    }

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'palete-ulazi'} />
                        <Link to='/inventure/rolne-trake/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Kreiraj inventuru
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default RepromaterijaliInvLista;
