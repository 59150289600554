import {useFormik} from 'formik'
import React, {useState} from 'react'
import * as Yup from 'yup'
import {updateStatusRepromaterijala} from '../../api'
import notifyToast from '../Toast/Toast'
import StatusRolneDropdown from '../StatusDropdown/StatusRolneDropdown'
import clsx from 'clsx'

const StatusComponent = ({rowSelection, updatePage}) => {
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        status: Yup.number().required(),
        RezBrRolne: Yup.number(),
        RezKupac: Yup.string().when('status', (status, schema) => {
            //  @ts-ignore
            if (status[0] === 3) {
                return schema.required('Required')
            } else {
                return schema
            }
        }),
        RezKolicina: Yup.string().when('status', (status, schema) => {
            //  @ts-ignore
            if (status[0] === 3) {
                return schema.required('Required')
            } else {
                return schema
            }
        }),
        RezKraj: Yup.date().when('status', (status, schema) => {
            //  @ts-ignore
            if (status[0] === 3) {
                return schema.required('Required')
            } else {
                return schema
            }
        }),
    })
    const initialValues = {
        status: undefined,
        RezKupac: undefined,
        RezKolicina: undefined,
        RezKraj: undefined,
        PonudaBroj: undefined,
        NalogBroj: undefined,
        RezNapomene: undefined,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true)
            const idList = rowSelection.map((item) => item.ReprMatId)

            let params = {
                stavkaId: idList,
                status: values.status,
            }
            if (values.status === '3') {
                const newParams = {
                    ...params,
                    RezKupac: values.RezKupac,
                    NalogBroj: values.NalogBroj,
                    PonudaBroj: values.PonudaBroj,
                    RezKolicina: values.RezKolicina,
                    RezKraj: values.RezKraj,
                }
                params = newParams
            }
            console.log({params})
            updateStatusRepromaterijala(params)
                .then((response) => {
                    console.log(response);

                    if (response.error === 0) {
                        notifyToast(true, response.message + ' Vaša izmjena statusa je dodatno evidentirana'); // Success toast
                        updatePage();
                        resetForm();
                    } else {
                        // API responded with error === 1 (controlled error case)
                        notifyToast(false, response.message); // Error toast
                        updatePage();
                        resetForm();
                    }
                })
                .catch((error) => {
                    console.error('Error:', error); // Log the error for debugging

                    // Extract the error message safely, based on your API structure
                    const errorMessage =
                        error?.response?.data?.message || // API error message (if available)
                        error.message || // JS error message
                        'Greška.'; // Default message if nothing is available

                    notifyToast(false, errorMessage); // Show error toast with the message
                })
                .finally(() => {
                    setLoading(false); // Reset loading state
                });

        },
    })
    return (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row'>
                <div className='col-lg-8 mb-3'>
                    <StatusRolneDropdown
                        value={formik.values.status}
                        onChange={(name, value) => formik.setFieldValue('status', value)}
                        selectName='Status'
                    />
                </div>
                <div className='col-lg-4 fv-row'>
                    <button
                        className='btn btn-block btn-primary'
                        type='submit'
                        disabled={!rowSelection.length || !formik.isValid || loading}
                    >
                        <i className="bi bi-check fs-2"></i>
                    </button>
                </div>
                {formik.values.status === '3' && (
                    <div className='alert alert-primary'>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Kupac</label>
                                <input
                                    type='text'
                                    placeholder='Kupac'
                                    {...formik.getFieldProps('RezKupac')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.RezKupac && formik.errors.RezKupac},
                                        {
                                            'is-valid': formik.touched.RezKupac && !formik.errors.RezKupac,
                                        }
                                    )}
                                />
                                {formik.touched.RezKupac && formik.errors.RezKupac && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.RezKupac}</div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12'>
                                <label className='col-form-label required fw-bold fs-6'>Kolicina(KG)</label>
                                <div className='col-lg-12'>
                                    <input
                                        type='text'
                                        placeholder='Kolicina'
                                        {...formik.getFieldProps('RezKolicina')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.RezKolicina && formik.errors.RezKolicina},
                                            {
                                                'is-valid': formik.touched.RezKolicina && !formik.errors.RezKolicina,
                                            }
                                        )}
                                    />
                                    {formik.touched.RezKolicina && formik.errors.RezKolicina && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.RezKolicina}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <label className='col-lg-8 col-form-label fw-bold fs-6'>REZERVACIJA PO OSNOVU</label>
                            <div className='col-lg-6'>
                                <label className='col-lg-10 col-form-label fw-bold fs-6'>Ponuda Broj</label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Ponuda Broj'
                                        {...formik.getFieldProps('PonudaBroj')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.PonudaBroj && formik.errors.PonudaBroj},
                                            {
                                                'is-valid': formik.touched.PonudaBroj && !formik.errors.PonudaBroj,
                                            }
                                        )}
                                    />
                                    {formik.touched.PonudaBroj && formik.errors.PonudaBroj && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.PonudaBroj}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-form-label fw-bold fs-6'>Nalog Broj</label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Nalog Broj'
                                        {...formik.getFieldProps('NalogBroj')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.NalogBroj && formik.errors.NalogBroj},
                                            {
                                                'is-valid': formik.touched.NalogBroj && !formik.errors.NalogBroj,
                                            }
                                        )}
                                    />
                                    {formik.touched.NalogBroj && formik.errors.NalogBroj && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.NalogBroj}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-lg-12'>
                                {/* Kraz Rezervacije */}
                                <div className='col-12 mb-6'>
                                    <label className='col-lg-8 col-form-label required fw-bold fs-6'>
                                        Kraj Rezervacije
                                    </label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Kraz Rezervacije'
                                            {...formik.getFieldProps('RezKraj')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.RezKraj && formik.errors.RezKraj,
                                                },
                                                {
                                                    'is-valid': formik.touched.RezKraj && !formik.errors.RezKraj,
                                                }
                                            )}
                                        />
                                        {formik.touched.RezKraj && formik.errors.RezKraj && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.RezKraj}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-10 col-form-label fw-bold fs-6'>Napomena</label>
                            <div className='col-lg-12 fv-row'>
                                    <textarea
                                        placeholder='Napomena'
                                        {...formik.getFieldProps('RezNapomene')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.RezNapomene && formik.errors.RezNapomene},
                                            {
                                                'is-valid': formik.touched.RezNapomene && !formik.errors.RezNapomene,
                                            }
                                        )}
                                    />
                                {formik.touched.RezNapomene && formik.errors.RezNapomene && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.RezNapomene}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </form>
    )
}

export default StatusComponent
