import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    getSumaRolniInventurePremaFmkSifri,
    izvjestajPremaLokaciji, izvjestajPremaLokacijiTrake,
    izvjestajZaInventuruRolni, izvjestajZaInventuruTraka, podesiNoveLokacijeRolni
} from "../../api/inventure";
import notifyToast from "../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import LokacijeRepromaterijalaDropdown
    from "../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown";
import LokacijeRsTraka from "../../components/LokacijeRSTraka/LokacijeRsTraka";

interface Rolna {
    Id: number;
    InventuraId: number;
    RolnaId: number;
    TrakaId?: number; // nullable
    Tezina: string;
    SkeniranaTezina: string;
    Lokacija: string;
    SkeniranaLokacija: string;
    SkeniranaLokacijaRed: number;
    Korisnik: number;
    Greska: number;
    Komentar?: string; // nullable
    Kreirano: string;
    Izmjenjeno: string;
}

interface ReportData {
    naStanju: {
        totalCount: number;
        totalQuantity: string;
    };
    skenirano: {
        totalCount: number;
        totalQuantity: string;
    };
    tezinaGreske: number;
    lokacijaGreske: number;
    notInSkenirano?: { [key: string]: number };
    notInNaStanju?: { [key: string]: number };
    rolneSaGreskomUTezini: Rolna[];
    rolneSaGreskomULokaciji: Rolna[];
}

const InventuraTrakaIzvjestaj: React.FC = () => {
    const [loading, setLoading] = useState(false)

    const {id} = useParams()
    const IdFromURL = Number(id)

    const [reportData, setReportData] = useState<ReportData | null>(null);
    const [fmkSifraKolicina, setFmkSifraKolicina] = useState({});

    const [lokacijaRolne, setLokacijaRolne] = useState('');
    const [errors, setErrors] = useState('');
    const [touched, setTouched] = useState(false);

    const [izvjestajLokacije, setIzvjestajLokacije] = useState('');

    // Example of a correct async function declaration in a React component
    const handleChange = async (value) => {
        console.log(value, 'value'); // Good for debugging to ensure value is correct
        const data = {
            lokacija: value,
            idInventure: IdFromURL  // Ensure this value is also correctly being retrieved
        };

        setLoading(true);
        try {
            const response = await izvjestajPremaLokacijiTrake(data);
            setLoading(false); // Turn off loading indicator
            setIzvjestajLokacije(response); // If response.data is needed, adjust accordingly
        } catch (error) {
            setLoading(false);
            console.error('Fetching error:', error);
        }
    };

    const formatData = () => {
        // Check if izvjestajLokacije is an object and has keys
        if (izvjestajLokacije && Object.keys(izvjestajLokacije).length > 0) {
            const values = Object.values(izvjestajLokacije).map(value => +value);  // Convert all values to numbers
            const maxRollNumber = Math.max(...values); // Finds the maximum value
            return `TRAKE: ${values.join(', ')}`;
        }

        return "No valid data available.";
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await getSumaRolniInventurePremaFmkSifri({IdFromURL});
                if (response && response.FMKSifraKolicina) {
                    setFmkSifraKolicina(response.FMKSifraKolicina);
                } else {
                    // Handle case where data might not be in the expected format
                    console.error('Invalid data format:', response);
                }
            } catch (error) {
                console.error('Fetching error:', error);
                // Optionally, handle errors, e.g., by setting an error message in state and displaying it
            }
            setLoading(false);
        };

        fetchData();
    }, [IdFromURL]); // Adjust dependencies as needed

    console.log(fmkSifraKolicina)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await izvjestajZaInventuruTraka({IdFromURL}); // Ensure you are sending the correct parameters

                if (data.error === 0) {
                    setReportData(data.data); // Store only the 'data' part if response is successful
                } else {
                    // Handle the case where API returns an error
                    console.error('API Error:', data.message);
                    // Optionally set some state to show the error message
                }

            } catch (error) {
                console.error('Fetching error:', error);
                // Optionally, handle errors, e.g., by setting an error message in state and displaying it
            }
            setLoading(false);
        };

        if (IdFromURL) fetchData(); // Only fetch data if IdFromURL is valid
    }, [IdFromURL]); // Rerun when IdFromURL changes

    const podesiNoveLokacije = async (value) => {
        try {
            const response = await podesiNoveLokacijeRolni({IdFromURL});
            if (response.error === 0) {
                notifyToast(true, response.message)
            } else {
                notifyToast(false, response.message)
            }
        } catch (error) {
            console.error('Fetching error:', error);
            // Optionally, handle errors, e.g., by setting an error message in state and displaying it
        }
    };


    return (
        <>
        <div className='row'>
            <ToastContainer/>
            <div className='col-6'>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h2>#{IdFromURL} INVENTURA </h2>
                        </div>
                    </div>
                    <div id='kt_account_profile_details' className='collapse show'>
                        {loading ? (
                            <LoadingComponent/>
                        ) : (
                            <div className='card-body border-top p-9 row'>
                                <div className="row mb-5">
                                    <div className="col-lg-6 col-sm-12">
                                        <h3>U sistemu [kom]: <span
                                            className="text-danger">{reportData?.naStanju.totalCount || 'N/A'}</span>
                                        </h3>  <br/>
                                        <h3>U sistemu [t]:{' '}
                                            <span className="text-danger">
                                                    {reportData?.naStanju.totalQuantity ?
                                                        new Intl.NumberFormat('bs-BA', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }).format(Number(reportData.naStanju.totalQuantity)) :
                                                        'N/A'}
                                                </span>
                                        </h3>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <h3>Skenirano [kom]: <span
                                            className="text-danger"> {reportData?.skenirano.totalCount || 'N/A'}</span>
                                        </h3>  <br/>
                                        <h3>Skenirano [t]:{' '}
                                            <span className="text-danger">
                                                    {reportData?.skenirano.totalQuantity || reportData?.skenirano.totalQuantity === '' ?
                                                        new Intl.NumberFormat('bs-BA', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }).format(Number(reportData.skenirano.totalQuantity || 0)) :
                                                        'N/A'}
                                                </span>
                                        </h3>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row mt-5">
                                    <div className="col-lg-6 col-sm-12">
                                        <h3>Greške težina [kom] : <span
                                            className="text-danger">{reportData?.tezinaGreske || 'N/A'} </span></h3>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <h3>Greške lokacija [kom] : <span
                                            className="text-danger">{reportData?.lokacijaGreske || 'N/A'} </span></h3>
                                        <br/>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h2>#Pregled po lokaciji | Trake nisu skenirane:</h2>
                        </div>
                    </div>
                    <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-5">
                                <div className="col-6">
                                    <label>Odaberi lokaciju</label>
                                    <LokacijeRsTraka
                                        value={lokacijaRolne}
                                        onChange={(name, value) => handleChange(value)}
                                        errors={errors}
                                        touched={touched}
                                        selectName='LokacijaTrake'
                                    />
                                </div>
                                <div className="col-12 mt-5">
                                    <h4>
                                        {formatData()}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h2>#Trake skenirane, razdužene u sistemu:</h2>
                        </div>
                    </div>
                    <div id='kt_account_profile_details' className='collapse show'>
                        {loading ? (
                            <LoadingComponent/>
                        ) : (
                            <div className='card-body border-top p-9'>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        {reportData?.notInNaStanju ? (
                                            <p style={{textAlign: 'justify'}}>
                                                {Object.keys(reportData.notInNaStanju).join(', ')}
                                            </p>
                                        ) : (
                                            <p>N/A</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h2># Greške u težini:</h2>
                        </div>
                    </div>
                    <div id='kt_account_profile_details' className='collapse show'>
                        {loading ? (
                            <LoadingComponent/>
                        ) : (
                            <div className='card-body border-top p-9'>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        {reportData?.rolneSaGreskomUTezini && reportData.rolneSaGreskomUTezini.length > 0 ? (
                                            <table className="table table-striped table-bordered">
                                                <thead className="thead-dark">
                                                <tr className="bg-primary">
                                                    <th>Traka</th>
                                                    <th>Težina Sistema</th>
                                                    <th>Skenirana Težina</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {reportData.rolneSaGreskomUTezini.map((rolna, index) => (
                                                    <tr key={index}>
                                                        <td>{rolna.TrakaId}</td>
                                                        <td>{rolna.Tezina}</td>
                                                        <td>{rolna.SkeniranaTezina}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p>N/A</p>
                                        )}
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h2># Greške u lokaciji:</h2>
                        </div>
                    </div>
                    <div id='kt_account_profile_details' className='collapse show'>
                        {loading ? (

                            <LoadingComponent/>
                        ) : (
                            <div className='card-body border-top p-9'>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        {reportData?.rolneSaGreskomULokaciji && reportData.rolneSaGreskomULokaciji.length > 0 ? (
                                            <table className="table table-striped table-bordered">
                                                <thead className="thead-dark">
                                                <tr className="bg-primary">
                                                    <th>Traka ID</th>
                                                    <th>Lokacija u Sistemu</th>
                                                    <th>Skenirana Lokacija</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {reportData.rolneSaGreskomULokaciji.map((rolna, index) => (
                                                    <tr key={index}>
                                                        <td>{rolna.TrakaId}</td>
                                                        <td>{rolna.Lokacija}</td>
                                                        <td>{rolna.SkeniranaLokacija}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p>N/A</p>
                                        )}
                                    </div>
                                </div>
                                <div className="separator"></div>
                                <div className="row">
                                    <button type="button"
                                            className="btn btn-primary"
                                            onClick={() => podesiNoveLokacije(true)}>PODESI NOVE LOKACIJE
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='card col-6 mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                    <div className='card-title m-0'>
                        <h2>#{IdFromURL} INVENTURA: <span className='text-danger'>Trake postoje u sistemu, nisu skenirane:</span></h2>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <div className='card-body border-top p-9'>
                            <div className="row mb-5">
                                <div className="col-12">
                                    {reportData?.notInSkenirano ? (
                                        <p style={{textAlign: 'justify'}}>
                                            {Object.keys(reportData.notInSkenirano).join(', ')}
                                        </p>
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
</>
)
}
export default InventuraTrakaIzvjestaj;
