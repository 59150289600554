import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import * as Yup from 'yup'
import notifyToast from '../Toast/Toast'
import {RezervacijeRolni} from '../../models/RezervacijeRolni'
import {createRezervacije, updateRezervacija} from '../../api/rezervacijeRolni'

interface Props {
    isModalOpen: boolean
    setisModalOpen: Function
    rolna: string
    rezervacijeData?: RezervacijeRolni
    updateData: Function
}

const ReproMaterijaliModal: FC<Props> = ({
                                             isModalOpen = false,
                                             setisModalOpen,
                                             rolna,
                                             rezervacijeData,
                                             updateData,
                                         }) => {
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        RezBrRolne: Yup.string(),
        RezKupac: Yup.string().required('Required'),
        RezKolicina: Yup.string().required('Required'),
        RezKraj: Yup.date().required('Required'),
    })
    const initialValues = {
        RezBrRolne: rolna,
        RezKupac: rezervacijeData?.RezKupac ?? undefined,
        RezKolicina: rezervacijeData?.RezKolicina ?? undefined,
        RezKraj: rezervacijeData?.RezKraj ?? undefined,
        RezPonuda: rezervacijeData?.RezPonuda ?? undefined,
        RezNalog: rezervacijeData?.RezNalog ?? undefined,
        RezNapomene: rezervacijeData?.RezNapomene ?? undefined,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {

            console.log(values, 'values')
            console.log(rezervacijeData, 'id')
            setLoading(true)
            try {
                let response
                values.RezBrRolne = rolna
                if (rezervacijeData) {
                    // Update reservation
                    response = await updateRezervacija(values, rezervacijeData.RezId)
                } else {
                    // Create reservation
                    response = await createRezervacije(values)
                }

                const responseData = response

                if (responseData.error === 0) {
                    notifyToast(true, responseData.message)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
                setisModalOpen(false)
                updateData()
            }
        },
    })
    return (
        <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
            <ModalHeader closeButton={true} className='h3'>
                Izmjena rezervacije
            </ModalHeader>
            <div className='p-10'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    {/* Repromaterijal */}
                    <div className='row'>
                        <div className='col-lg-12'>
                            <label className='col-lg-4 col-form-label required'>Repromaterijal</label>
                            <div className='col-lg-12 fv-row'>
                                <input
                                    disabled={true}
                                    type='text'
                                    placeholder='RezBrRolne'
                                    {...formik.getFieldProps('RezBrRolne')}
                                    value={rolna}
                                    className={clsx('form-control form-control-lg form-control-solid is-valid')}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Kupac</label>
                                <input
                                    type='text'
                                    placeholder='Kupac'
                                    {...formik.getFieldProps('RezKupac')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.RezKupac && formik.errors.RezKupac},
                                        {
                                            'is-valid': formik.touched.RezKupac && !formik.errors.RezKupac,
                                        }
                                    )}
                                />
                                {formik.touched.RezKupac && formik.errors.RezKupac && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.RezKupac}</div>
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-form-label required fw-bold fs-6'>Kolicina(KG)</label>
                                <div className='col-lg-12'>
                                    <input
                                        type='text'
                                        placeholder='Kolicina'
                                        {...formik.getFieldProps('RezKolicina')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.RezKolicina && formik.errors.RezKolicina},
                                            {
                                                'is-valid': formik.touched.RezKolicina && !formik.errors.RezKolicina,
                                            }
                                        )}
                                    />
                                    {formik.touched.RezKolicina && formik.errors.RezKolicina && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.RezKolicina}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <label className='col-lg-8 col-form-label fw-bold fs-6'>REZERVACIJA PO OSNOVU</label>
                            <div className='col-lg-6'>
                                <label className='col-lg-10 col-form-label fw-bold fs-6'>Ponuda Broj</label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Ponuda Broj'
                                        {...formik.getFieldProps('RezPonuda')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.RezPonuda && formik.errors.RezPonuda},
                                            {
                                                'is-valid': formik.touched.RezPonuda && !formik.errors.RezPonuda,
                                            }
                                        )}
                                    />
                                    {formik.touched.RezPonuda && formik.errors.RezPonuda && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.RezPonuda}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-form-label fw-bold fs-6'>Nalog Broj</label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Nalog Broj'
                                        {...formik.getFieldProps('RezNalog')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.RezNalog && formik.errors.RezNalog},
                                            {
                                                'is-valid': formik.touched.RezNalog && !formik.errors.RezNalog,
                                            }
                                        )}
                                    />
                                    {formik.touched.RezNalog && formik.errors.RezNalog && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.RezNalog}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                {/* Kraz Rezervacije */}
                                <div className='col mb-6'>
                                    <label className='col-lg-8 col-form-label fw-bold fs-6'>Kraj Rezervacije</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Kraz Rezervacije'
                                            {...formik.getFieldProps('RezKraj')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.RezKraj && formik.errors.RezKraj,
                                                },
                                                {
                                                    'is-valid': formik.touched.RezKraj && !formik.errors.RezKraj,
                                                }
                                            )}
                                        />
                                        {formik.touched.RezKraj && formik.errors.RezKraj && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.RezKraj}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-10 col-form-label fw-bold fs-6'>Napomena</label>
                            <div className='col-lg-12 fv-row'>
                                <textarea
                                    placeholder='Napomena'
                                    {...formik.getFieldProps('RezNapomene')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.RezNapomene && formik.errors.RezNapomene},
                                        {
                                            'is-valid': formik.touched.RezNapomene && !formik.errors.RezNapomene,
                                        }
                                    )}
                                />
                                {formik.touched.RezNapomene && formik.errors.RezNapomene && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.RezNapomene}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='d-flex col justify-content-around p-5'>
                            <button
                                type='button'
                                className='btn btn-danger btn-sm '
                                onClick={() => setisModalOpen(false)}
                            >
                                Zatvori
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ReproMaterijaliModal
