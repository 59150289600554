import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {RolnaDetaljiProizvodnja} from "../../../models/FmkNaloziModel";
import {direktanIzlazRolne, fetchRepromaterijalPromet} from "../../../api/prometRepromaterijala";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../../components/Toast/Toast";
import useDebounce from "../../../hooks/useDebounce";
import {rolnaDetaljiProizvodnja, rolnaDetaljiProizvodnjaV2, rolnaDetaljiProizvodnjaV3} from "../../../api/fmkNalozi";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import clsx from "clsx";
import LokacijeRepromaterijalaDropdown
    from "../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown";
import {InventureRolneModel} from "../../../models/inventure";
import {
    aktivnaInventura,
    fetchInventura, inventuraRolneGreskaLokacija, inventuraRolneGreskaTezinaLokacija,
    inventuraSveInformacijeTacne,
    zadnjaUpisanaRolna
} from "../../../api/inventure";

const InventuraRolne: React.FC = () => {
    const {id} = useParams()

    const initialValues = {
        InventuraId: undefined,
        RolnaId: undefined,
        SkeniranaTezina: undefined,
        SkeniranaLokacija: undefined,
        SkeniraniRed: undefined,
        Greska: undefined,
        Komentar: undefined,
    }

    const [loading, setLoading] = useState(false)
    const [novaTezina, setNovaTezina] = useState(false)
    const [idInventure, setIdInventure] = useState('')
    const [zadnjaRolna, setZadnjaRolna] = useState('')
    const [initialData, setInitialData] = useState<Partial<InventureRolneModel>>(initialValues)
    const [rolnaDetaljiInfo, setrolnaDetalji] = useState<RolnaDetaljiProizvodnja>()
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {
        aktivnaInventura()
            .then((data) => {
                console.log(data);
                if (typeof data.Id !== 'undefined') {
                    const idString = data.Id.toString();
                    setIdInventure(idString); // Set state
                    // Call the next function in the chain after converting Id
                    return zadnjaUpisanaRolna(data.Id); // Directly use data.Id since state update is asynchronous
                } else {
                    setIdInventure('defaultId'); // Set a default or handle error
                    return Promise.reject('Invalid Inventura ID'); // Skip the next API call
                }
            })
            .then((rolnaData) => {
                // This code executes after zadnjaUpisanaRolna successfully returns
                if (rolnaData && rolnaData.RolnaId) {
                    setZadnjaRolna(rolnaData.RolnaId.toString());
                }
            })
            .catch((error) => {
                console.error('An error occurred:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [refreshCounter]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const validationSchema = Yup.object({
        RolnaId: Yup.string().min(5, 'Must be at least 4 characters').required('Required'),
        InventuraId: Yup.number().required('Required'),
        SkeniranaLokacija: Yup.string().min(5, 'Must be at least 4 characters').required('Required'),
    })

    const handleGreskaTezina = () => {
        setNovaTezina(prevNovaTezina => !prevNovaTezina);
    }

    const handleSveOk = () => {
        const data = {
            IdInventure: idInventure,
            RolnaId: formik.values.RolnaId
        };

        inventuraSveInformacijeTacne(data)
            .then(response => {
                formik.setFieldValue('RolnaId', '');
                setrolnaDetalji(undefined)
                setRefreshCounter(prevCounter => prevCounter + 1);
                if (inputRef.current) inputRef.current.focus();
                if(response.error === 0) {
                    notifyToast(true, response.message)
                }else{
                    notifyToast(false, response.message)
                }
            })
            .catch(error => {
                console.error("Error:", error);
                notifyToast(false, error.message)
            });
    }
    const greskaLokacija = () => {
        const data = {
            IdInventure: idInventure,
            RolnaId: formik.values.RolnaId,
            SkeniranaLokacija: formik.values.SkeniranaLokacija,
            SkeniranaLokacijaRed: formik.values.SkeniranaLokacijaRed,
        };

        inventuraRolneGreskaLokacija(data)
            .then(response => {
                formik.setFieldValue('RolnaId', '');
                setrolnaDetalji(undefined)
                setRefreshCounter(prevCounter => prevCounter + 1);
                if (inputRef.current) inputRef.current.focus();
                if(response.error === 0) {
                    notifyToast(true, response.message)
                }else{
                    notifyToast(false, response.message)
                }
            })
            .catch(error => {
                console.error("Error:", error);
                notifyToast(false, error.message)
            });
    }
    const greskaTezinaLokacija = () => {
        const data = {
            IdInventure: idInventure,
            RolnaId: formik.values.RolnaId,
            SkeniranaTezina: formik.values.SkeniranaTezina,
            SkeniranaLokacija: formik.values.SkeniranaLokacija,
            SkeniranaLokacijaRed: formik.values.SkeniranaLokacijaRed,
        };

        inventuraRolneGreskaTezinaLokacija(data)
            .then(response => {
                formik.setFieldValue('RolnaId', '');
                setrolnaDetalji(undefined)
                setRefreshCounter(prevCounter => prevCounter + 1);
                if (inputRef.current) inputRef.current.focus();
                if(response.error === 0) {
                    notifyToast(true, response.message)
                }else{
                    notifyToast(false, response.message)
                }
            })
            .catch(error => {
                console.error("Error:", error);
                notifyToast(false, error.message)
            });
        formik.setFieldValue('SkeniranaTezina', '');
        setNovaTezina(false)
    }

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true);

        },
    });
    const debouncedRolna = useDebounce(formik.values.RolnaId)
    useEffect(() => {
        if (debouncedRolna) {
            rolnaDetaljiProizvodnjaV3({rolnaId: debouncedRolna})
                .then((response) => {
                    if (response.data) {
                        setrolnaDetalji(response.data)
                    } else {
                        setrolnaDetalji(response)
                    }
                })
                .catch((error) => notifyToast(false, error.response.data.message))
        }
    }, [debouncedRolna])

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Inventura rolni'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            {/* ROLNA */}
                            <h3>ID INVENTURE: <span className='text-danger'>{idInventure}</span></h3><br/>
                            <h3>ZADNJA UPISANA ROLNA: <span className='text-danger'>{zadnjaRolna}</span></h3>
                            {idInventure ? (
                                <div className='col-lg-12'>
                                    <div className="row">
                                        <div className='col-8 mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>Lokacija</span>
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <LokacijeRepromaterijalaDropdown
                                                    value={formik.values.SkeniranaLokacija}
                                                    onChange={(name, value) => formik.setFieldValue('SkeniranaLokacija', value)}
                                                    errors={formik.errors.SkeniranaLokacija}
                                                    touched={formik.touched.SkeniranaLokacija}
                                                    selectName='SkeniranaLokacija'
                                                />
                                            </div>
                                        </div>
                                        {/* ReprMat Lokacija2 */}
                                        <div className='col-4 mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span>Red</span>
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    {...formik.getFieldProps('SkeniranaLokacijaRed')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        {
                                                            'is-invalid':
                                                                formik.touched.SkeniranaLokacijaRed && formik.errors.SkeniranaLokacijaRed,
                                                        },
                                                        {
                                                            'is-valid':
                                                                formik.touched.SkeniranaLokacijaRed && !formik.errors.SkeniranaLokacijaRed,
                                                        }
                                                    )}
                                                >
                                                    <option value=''>Odaberi..</option>
                                                    <option value='1'>1</option>
                                                    <option value='2'>2</option>
                                                    <option value='3'>3</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <QrCodeScanner
                                                onScan={(data) => {
                                                    const text = data.split(';')[0]
                                                    formik.setFieldValue('RolnaId', text)
                                                }}
                                            />
                                        </div>
                                        <div className='col-10'>
                                            <input
                                                type='text'
                                                placeholder='Rolna'
                                                {...formik.getFieldProps('RolnaId')}
                                                ref={inputRef}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid':
                                                            formik.touched.RolnaId && formik.errors.RolnaId,
                                                    },
                                                    {
                                                        'is-valid':
                                                            formik.touched.RolnaId && !formik.errors.RolnaId,
                                                    }
                                                )}
                                            />
                                            {formik.touched.RolnaId && formik.errors.RolnaId && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.RolnaId}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-10'>
                                            {rolnaDetaljiInfo ? (
                                                <div className='col-12 border rounded border-success p-2'>
                                                    <div>
                                                        <h2>
                                                            Težina: {rolnaDetaljiInfo.ReprMatTrenutnaKolicina || 'N/A'}
                                                            <br/>
                                                            Debljina: {rolnaDetaljiInfo.ReprMatDebljina || 'N/A'} <br/>
                                                            Sirina:{' '}
                                                            {rolnaDetaljiInfo.ReprMatSirina || 'N/A'} <br/>
                                                            Lokacija:{' '}
                                                            {rolnaDetaljiInfo.ReprMatLokacija || 'N/A'} <br/>
                                                            Red:{' '}
                                                            {rolnaDetaljiInfo.ReprMatLokacija2 || 'N/A'} <br/>
                                                            <hr/>
                                                            Boja:{' '}
                                                            {rolnaDetaljiInfo.boja_lica?.BojaNaziv || 'N/A'} <br/>
                                                            Proizvođač:{' '}
                                                            {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'} <br/>
                                                            Šarža:{' '}
                                                            {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'} <br/>
                                                        </h2>
                                                    </div>

                                                </div>
                                            ) : (
                                                <div>Loading or no data...</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="alert alert-danger">
                                    Nema aktivna inventura
                                </div>
                            )
                            }
                            <hr/>
                            <div className='row mb-5'>
                                {!novaTezina && (
                                    <button
                                        type='button'
                                        className='btn btn-success col-3'
                                        onClick={handleSveOk}
                                    >
                                        {!loading && 'Sve Ok'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                        )}
                                    </button>
                                )}
                                <button
                                    type='button'
                                    className='btn btn-danger col-3 offset-1'
                                    onClick={handleGreskaTezina}
                                >
                                    GREŠKA TEŽINA
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary col-3 offset-1'
                                    onClick={greskaLokacija}
                                >
                                    GREŠKA LOKACIJA
                                </button>
                            </div>
                            {novaTezina && (
                                <div className='row mt-5'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Težina rolne</label>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='decimal'
                                            placeholder='Tezina'
                                            {...formik.getFieldProps('SkeniranaTezina')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.SkeniranaTezina && formik.errors.SkeniranaTezina,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.SkeniranaTezina && !formik.errors.SkeniranaTezina,
                                                }
                                            )}
                                        />
                                        {formik.touched.SkeniranaTezina && formik.errors.SkeniranaTezina && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.SkeniranaTezina}</div>
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-primary col-4 mt-4'
                                        onClick={greskaTezinaLokacija}
                                    >
                                        {!loading && 'Potvrdi'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default InventuraRolne;
